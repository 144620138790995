import { useAuthUser } from "@/core/context/AuthUserContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import React from "react"
import PageContent from "../../../../main/page/content/PageContent"

interface Props {
  description?: string
  ctaButton?: React.ReactNode
}
function NotFoundPageContent({ description, ctaButton }: Props) {
  const { authUser } = useAuthUser()
  const classes = useStyles()
  const theme = useTheme()

  return (
    <PageContent customClassName={classes.container}>
      <DiscoText
        data-testid={"NotFoundPage.title"}
        marginBottom={3}
        variant={"heading-xxl"}
      >
        {"Oops!"}
      </DiscoText>

      <DiscoText
        data-testid={"NotFoundPage.description"}
        variant={"heading-md"}
        marginBottom={4}
        className={classes.description}
        color={theme.palette.type === "dark" ? "groovy.onDark.300" : "groovy.grey.500"}
      >
        {description || getDefaultDescription()}
      </DiscoText>

      {ctaButton ||
        (!authUser && (
          <DiscoButton
            to={{
              pathname: ROUTE_NAMES.AUTHENTICATION.LOGIN,
              state: { redirectUrl: window.location.href },
            }}
            data-testid={"NotFoundPage.button.login"}
            className={classes.loginButton}
          >
            {`Login`}
          </DiscoButton>
        ))}
    </PageContent>
  )

  function getDefaultDescription() {
    return authUser
      ? "We can't seem to find the page you're looking for or you don't have access to this page."
      : "You need to login to access this page."
  }
}

const useStyles = makeUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "120px",
    textAlign: "center",
  },
  description: {
    whiteSpace: "pre-line",
  },
  loginButton: {
    margin: "0 auto",
    width: "fit-content",
  },
})

export default NotFoundPageContent
