/*
As of Babel 7.4.0, @babel/polyfill package has been deprecated in favor of directly including
- core-js/stable (to polyfill ECMAScript features)
- regenerator-runtime/runtime (needed to use transpiled generator functions):
*/

import {
  CoreIndexSourceQuery,
  CoreIndexSourceQuery$data,
} from "@/core/__generated__/CoreIndexSourceQuery.graphql"
import RetryableErrorOverlayFallback from "@/core/error/RetryableErrorOverlayFallback"
import AppWithContextProviders from "@/core/root-app/AppWithContextProviders"
import { sendSentryAnException } from "@/core/sentryHandler"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import userConfirmationModal from "@/unsavedChangesRouter/userConfirmationModal"
import ToastProvider from "@components/toast/ToastProvider"
import { DiscoThemeProvider } from "@disco-ui"
// Figtree Fontface
import "@fontsource/figtree/400.css"
import "@fontsource/figtree/500.css"
import "@fontsource/figtree/600.css"
import "@fontsource/figtree/700.css"
import { CssBaseline } from "@material-ui/core"
import { isE2ETest } from "@utils/e2e"
import { E2EMockFlagsProvider } from "@utils/hook/useFeatureFlags"
import { isWebView } from "@utils/webView/webViewUtils"
import "core-js/stable"
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk"
import { StrictMode } from "react"
import ReactDOM from "react-dom"
import { graphql, RelayEnvironmentProvider } from "react-relay"
import { BrowserRouter } from "react-router-dom"
import "regenerator-runtime/runtime"
import "stream-chat-react/dist/css/index.css"
import RelayEnvironment from "../relay/RelayEnvironment"
import { isNetworkError } from "./error/ErrorBoundary"
import ROUTE_NAMES from "./route/util/routeNames"
import { redirectToDomain } from "./route/util/routeUtils"
import { appTheme } from "./ui/style/appMuiTheme"

export default function loadApp() {
  // Check if current domain belongs to an organization
  Relay.runQuery<CoreIndexSourceQuery>(
    graphql`
      query CoreIndexSourceQuery($domain: String!) {
        domain: domainMapping(domain: $domain) {
          id
          isPrimary
          status
          isDefault
          organization {
            id
            name
            slug
            primaryDomain
            creationDatetime
          }
        }
        viewer {
          id
          email
          lastActiveOrganizationMembership {
            organization {
              primaryDomain
            }
          }
        }
      }
    `,
    {
      domain: window.location.origin,
    },
    {
      fetchPolicy: "network-only",
    }
  )
    .then(async (res) => {
      if (!res) return

      const authUser = res.viewer
      // If the domain mapping doesn't exist, redirect to the application base
      if (!res.domain?.organization) {
        // If we are on the application base domain, bootstrap otherwise
        // reload to the base domain
        if (window.location.origin === BASE_DOMAIN_URL) {
          await checkUserAndBootstrapApp(undefined, authUser)
          return
        }
        redirectToDomain(
          BASE_DOMAIN_URL,
          window.location.pathname,
          window.location.search
        )
        return
      }

      // If we are not on the disco domain for this community, and it is not the primary domain
      // then redirect to whatever the organization returns as its primary domain. Do not redirect
      // if we are in webview mode
      if (!res.domain.isPrimary && !res.domain.isDefault && !isWebView()) {
        redirectToDomain(
          res.domain.organization.primaryDomain,
          window.location.pathname,
          window.location.search
        )
        return
      }

      await checkUserAndBootstrapApp(res.domain.organization, authUser)
    })
    .catch((err) => {
      // Render the retryable error. If we have any failures here now they will be caused from the query
      // and we don't want there just being a constant retry loop without us knowing.
      console.error(err)
      if (!isNetworkError(err)) {
        sendSentryAnException(err, {
          extra: {
            title: "bootstrapApp",
          },
        })
      }
      const rootElement = document.getElementById("root")!
      rootElement.innerHTML = "" // Clear the loading dots before we render the app in
      ReactDOM.render(
        <DiscoThemeProvider theme={appTheme}>
          <RetryableErrorOverlayFallback />
        </DiscoThemeProvider>,
        rootElement
      )
    })
}

async function checkUserAndBootstrapApp(
  organization?: {
    id: GlobalID
    name: string
    slug: string
    creationDatetime: string
    primaryDomain: string
  },
  authUser?: CoreIndexSourceQuery$data["viewer"]
) {
  const rootElement = document.getElementById("root")
  if (!rootElement) return

  let app = <BootstrapApp />

  if (organization && LAUNCH_DARKLY_CLIENT_SIDE_ID && !isE2ETest()) {
    const LDProvider = await asyncWithLDProvider({
      clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_ID,
      context: {
        kind: "user",
        key: Relay.rawId(organization.id),
        name: organization.name,
        email: `${organization.slug}@app.disco.co`,
        creationTimestamp: new Date(organization.creationDatetime).getTime(),
      },
      options: {
        // Disable sync xhr request on pageunload that throws in Chrome:
        // https://github.com/launchdarkly/js-client-sdk/issues/147
        disableSyncEventPost: true,
      },
    })
    app = <LDProvider>{app}</LDProvider>
  }

  if (authUser) {
    const isCreatingCommunity = window.location.pathname.includes(
      ROUTE_NAMES.ONBOARDING.NEW_COMMUNITY
    )
    const isSigningUp = window.location.pathname === ROUTE_NAMES.ONBOARDING.SIGN_UP

    // used by e2e tests
    const isLogout = window.location.pathname === ROUTE_NAMES.AUTHENTICATION.LOGOUT

    // Any of the auth endpoints should not first redirect to the last active org
    const isAuth =
      window.location.pathname === ROUTE_NAMES.AUTHENTICATION.SOCIAL_AUTH_REDIRECT ||
      window.location.pathname === ROUTE_NAMES.AUTHENTICATION.TOKEN_EXCHANGE ||
      window.location.pathname === ROUTE_NAMES.AUTHENTICATION.SSO_AUTH

    if (
      !organization &&
      !isSigningUp &&
      !isCreatingCommunity &&
      !isLogout &&
      !isAuth &&
      authUser.lastActiveOrganizationMembership?.organization.primaryDomain &&
      redirectToDomain(
        authUser.lastActiveOrganizationMembership.organization.primaryDomain,
        window.location.pathname,
        window.location.search
      )
    ) {
      return
    }
  }
  if (isE2ETest()) {
    app = <E2EMockFlagsProvider>{app}</E2EMockFlagsProvider>
  }
  ReactDOM.render(app, rootElement)
}

function BootstrapApp() {
  return (
    <StrictMode>
      <DiscoThemeProvider theme={appTheme}>
        {/**
         *  Apply page-wide defaults
         *  Note: This component needs to be the highest in the tree so the styles are loaded first and avoid FOUC
         */}
        <CssBaseline />
        <RelayEnvironmentProvider environment={RelayEnvironment}>
          <BrowserRouter
            basename={"/"}
            getUserConfirmation={(message, callback) => {
              userConfirmationModal(message, callback)
            }}
          >
            <ToastProvider>
              <AppWithContextProviders />
            </ToastProvider>
          </BrowserRouter>
        </RelayEnvironmentProvider>
      </DiscoThemeProvider>
    </StrictMode>
  )
}
