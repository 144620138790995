/**
 * @generated SignedSource<<776a1dba76e5aa8a76de85a7393615da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
export type DashboardLayout = "one_column" | "two_column" | "%future added value";
export type LandingPageMode = "internal" | "external" | "none" | "%future added value";
export type ProductRegistrationAvailability = "public" | "private" | "hidden" | "%future added value";
export type ProductRole = "manager" | "instructor" | "member" | "%future added value";
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ActiveProductContextFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly slug: string;
  readonly type: ProductType;
  readonly status: ProductStatus;
  readonly registrationAvailability: ProductRegistrationAvailability;
  readonly startDate: string | null;
  readonly endDate: string | null;
  readonly startsAt: string | null;
  readonly endsAt: string | null;
  readonly cover: string;
  readonly description: string | null;
  readonly richEditorCheckoutDescription: string | null;
  readonly landingPageUrl: string | null;
  readonly creationDatetime: string;
  readonly isMembersAppVisibleToAll: boolean;
  readonly landingPage: {
    readonly mode: LandingPageMode;
    readonly metaTitle: string | null;
    readonly metaDescription: string | null;
    readonly metaImageUrl: string | null;
  } | null;
  readonly typeTag: {
    readonly label: string;
  } | null;
  readonly badge: {
    readonly id: string;
    readonly kind: BadgeKind;
    readonly icon: string | null;
    readonly color: string | null;
    readonly emoji: string | null;
    readonly mediaUrl: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
  } | null;
  readonly viewerMembership: {
    readonly id: string;
    readonly memberId: string;
    readonly role: ProductRole;
  } | null;
  readonly curriculum: {
    readonly id: string;
    readonly showCompletedUsers: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"ContentModuleUtils_useCurriculumModuleIdsFragment" | "ContentModuleUtils_useOnCurriculumModuleCompletedFragment">;
  } | null;
  readonly memberGroups: {
    readonly totalCount: number;
  };
  readonly nextOrLastOccurrence: {
    readonly id: string;
    readonly datetimeRange: ReadonlyArray<string>;
  } | null;
  readonly dashboard: {
    readonly id: string;
    readonly layout: DashboardLayout;
  } | null;
  readonly tmpAllowExternalRegistrations: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"appLevelContextFragment" | "useIsWaitingRoomEnabledActiveProductFragment" | "ProductRegistrationButtonActiveProductFragment" | "usePermissionsFragment" | "useConnectedProductAppsActiveProductFragment" | "ExperienceHeader_ProductFragment" | "HeaderContent_ProductAppsFragment" | "ProductBadgeFragment">;
  readonly " $fragmentType": "ActiveProductContextFragment";
};
export type ActiveProductContextFragment$key = {
  readonly " $data"?: ActiveProductContextFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActiveProductContextFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActiveProductContextFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationAvailability",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "richEditorCheckoutDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "landingPageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creationDatetime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMembersAppVisibleToAll",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LandingPage",
      "kind": "LinkedField",
      "name": "landingPage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metaTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metaDescription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metaImageUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tag",
      "kind": "LinkedField",
      "name": "typeTag",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mediaUrl",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BadgeFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductMembership",
      "kind": "LinkedField",
      "name": "viewerMembership",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "memberId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Curriculum",
      "kind": "LinkedField",
      "name": "curriculum",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showCompletedUsers",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContentModuleUtils_useCurriculumModuleIdsFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContentModuleUtils_useOnCurriculumModuleCompletedFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "kind",
          "value": "custom"
        }
      ],
      "concreteType": "MemberGroupNodeConnection",
      "kind": "LinkedField",
      "name": "memberGroups",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "memberGroups(kind:\"custom\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Occurrence",
      "kind": "LinkedField",
      "name": "nextOrLastOccurrence",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "datetimeRange",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Dashboard",
      "kind": "LinkedField",
      "name": "dashboard",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "layout",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tmpAllowExternalRegistrations",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "appLevelContextFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useIsWaitingRoomEnabledActiveProductFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductRegistrationButtonActiveProductFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useConnectedProductAppsActiveProductFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExperienceHeader_ProductFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderContent_ProductAppsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductBadgeFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "1c9301ca237053b38fca435020e9603c";

export default node;
