import { HeadersDefaults } from "axios"

const JSON_CONTENT_TYPE = "application/json"
const FORM_DATA_CONTENT_TYPE = "multipart/form-data"
const CONTENT_TYPE_HEADER = "Content-Type"
const REQUEST_TYPES_WITH_BODY: Array<keyof HeadersDefaults> = ["post", "put", "patch"]

const MULTI_PART_REQUEST_HEADERS = {
  [CONTENT_TYPE_HEADER]: [FORM_DATA_CONTENT_TYPE],
}

const HTTP_STATUS_CODES = {
  FORBIDDEN: 403,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  TOO_LARGE: 413,
  THROTTLED: 429,
}

const DEFAULT_PAGINATION_LIMIT = 10
const POLLING_INTERVAL = 30000
const LIST_REQUEST_LIMIT_ALL = "all" as const

export {
  JSON_CONTENT_TYPE,
  CONTENT_TYPE_HEADER,
  REQUEST_TYPES_WITH_BODY,
  HTTP_STATUS_CODES,
  FORM_DATA_CONTENT_TYPE,
  DEFAULT_PAGINATION_LIMIT,
  MULTI_PART_REQUEST_HEADERS,
  POLLING_INTERVAL,
  LIST_REQUEST_LIMIT_ALL,
}
