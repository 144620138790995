/**
 * @generated SignedSource<<99a71d307b7d0fc52be684c96dc392a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LabelKind = "product_member" | "product_instructor" | "product_admin" | "organization_member" | "organization_admin" | "organization_owner" | "experience" | "admin_experience" | "admin_member" | "curriculum" | "custom" | "lesson" | "assignment" | "quiz" | "sidebar_community" | "sidebar_product" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type LabelsContext_Label$data = {
  readonly kind: LabelKind;
  readonly singular: string;
  readonly plural: string;
  readonly description: string;
  readonly " $fragmentType": "LabelsContext_Label";
};
export type LabelsContext_Label$key = {
  readonly " $data"?: LabelsContext_Label$data;
  readonly " $fragmentSpreads": FragmentRefs<"LabelsContext_Label">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LabelsContext_Label",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "singular",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plural",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "Label",
  "abstractKey": null
};

(node as any).hash = "e01afca3d94d618d229142224d343c26";

export default node;
