import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { createContext, useCallback, useContext, useState } from "react"

interface UnsavedChangesModalProviderProps {
  children: React.ReactNode
  unsavedChanges: boolean
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>
  isOpen: boolean
  /** Opens the modal and sets the onLeave action if unsaved changes, otherwise triggers onLeave action */
  handleLeave: (props: UnsavedChangesModalOpenProps) => void
  /** Close the modal and triggers onLeave action */
  leave: () => void
  /** Close the modal, but stay where editing */
  stay: () => void
}

/** Modal component that will trigger on unsaved changes condition */
function UnsavedChangesModalProvider({
  children,
  unsavedChanges,
  setUnsavedChanges,
  isOpen,
  handleLeave,
  leave,
  stay,
}: UnsavedChangesModalProviderProps) {
  return (
    <UnsavedChangesModalContext.Provider
      value={{
        unsavedChanges,
        setUnsavedChanges,
        isOpen,
        handleLeave,
      }}
    >
      {children}
      <DiscoWarningModal
        testid={"UnsavedChangesModalProvider.DiscoWarningModal"}
        modalContentLabel={"unsaved changes"}
        variant={"primary"}
        icon={"save"}
        title={"Heading away? Be sure to save your edits!"}
        description={
          "It appears you have unsaved changes. Be sure to save your work before navigating away!"
        }
        isOpen={isOpen}
        onClose={stay}
        // Confirm = stay and edit
        confirmationButtonProps={{
          onClick: stay,
          children: "Continue Editing",
        }}
        // Cancel = leave without saving
        cancelButtonText={"Exit Without Saving"}
        onCancel={leave}
      />
    </UnsavedChangesModalContext.Provider>
  )
}

export default UnsavedChangesModalProvider

/** unsaved changes modal is displayed if trying to leave without saving changes */
const UnsavedChangesModalContext = createContext<{
  unsavedChanges: boolean
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>
  isOpen: boolean
  /** When called will open the unsaved modal if changes are present otherwise will call the passed onLeave function */
  handleLeave: (props: UnsavedChangesModalOpenProps) => void
}>({
  unsavedChanges: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUnsavedChanges: () => {},
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleLeave: (props: UnsavedChangesModalOpenProps) => {
    // by default just call onLeave
    props.onLeave()
  },
})

export const useUnsavedChangesModalContext = () => useContext(UnsavedChangesModalContext)

type UnsavedChangesModalOpenProps = {
  onLeave: VoidFunction
  forceOpenUnsavedChangesModal?: boolean
}

export function useInitUnsavedChangesModalContext(): Omit<
  UnsavedChangesModalProviderProps,
  "children"
> & { setOpenModal: React.Dispatch<React.SetStateAction<boolean>> } {
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [onLeave, setOnLeave] = useState<VoidFunction | null>(null)

  const handleLeave = (props: UnsavedChangesModalOpenProps) => {
    if (unsavedChanges || props.forceOpenUnsavedChangesModal) {
      setOnLeave(() => props.onLeave)
      setOpenModal(true)
    } else {
      props.onLeave()
    }
  }

  return {
    unsavedChanges,
    setUnsavedChanges,
    isOpen: openModal,
    handleLeave,
    stay: () => {
      setOpenModal(false)
      setOnLeave(null)
    },
    leave: useCallback(() => {
      setUnsavedChanges(false)
      setOpenModal(false)
      if (onLeave) onLeave()
    }, [onLeave]),
    setOpenModal,
  }
}
