/**
 * @generated SignedSource<<4ff45489ce6312fc697da51e0a0ab7ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GlobalDrawerProvider_HistoricalSlugQuery$variables = {
  organizationId: string;
  slug: string;
};
export type GlobalDrawerProvider_HistoricalSlugQuery$data = {
  readonly historicalSlug: {
    readonly contentUsageId: string | null;
  } | null;
};
export type GlobalDrawerProvider_HistoricalSlugQuery = {
  variables: GlobalDrawerProvider_HistoricalSlugQuery$variables;
  response: GlobalDrawerProvider_HistoricalSlugQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentUsageId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GlobalDrawerProvider_HistoricalSlugQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SlugHistory",
        "kind": "LinkedField",
        "name": "historicalSlug",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GlobalDrawerProvider_HistoricalSlugQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SlugHistory",
        "kind": "LinkedField",
        "name": "historicalSlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a578f07d28a5a13e0c1a437629bbfb2e",
    "id": null,
    "metadata": {},
    "name": "GlobalDrawerProvider_HistoricalSlugQuery",
    "operationKind": "query",
    "text": "query GlobalDrawerProvider_HistoricalSlugQuery(\n  $organizationId: String!\n  $slug: String!\n) {\n  historicalSlug(organizationId: $organizationId, slug: $slug) {\n    contentUsageId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "872b1edb440776da665ec6132b002581";

export default node;
