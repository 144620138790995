import { SlackUserImportPageSkeleton } from "@/admin/integrations/slack/setup/SlackUserImportPage"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import {
  ActiveSlackUserImportContextRefetchableQuery,
  ActiveSlackUserImportContextRefetchableQuery$data,
} from "@/core/context/__generated__/ActiveSlackUserImportContextRefetchableQuery.graphql"
import { ActiveSlackUserImportContext_PendingMatchesQuery } from "@/core/context/__generated__/ActiveSlackUserImportContext_PendingMatchesQuery.graphql"
import RelayEnvironment from "@/relay/RelayEnvironment"
import Relay from "@/relay/relayUtils"
import useInterval from "@utils/hook/useInterval"
import { ReactNode, createContext, useContext, useMemo, useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { commitLocalUpdate, graphql } from "relay-runtime"

interface ActiveSlackUserImportProviderProps {
  children: ReactNode
}

type ActiveSlackUserImportContextValue = {
  activeImport: ActiveSlackUserImportNode | null
  startPoll: () => void
  isPollStarted: boolean
  pendingMatches?: number
  addMatch: VoidFunction
  removeMatch: VoidFunction
}

type ActiveSlackUserImportNode = NonNullable<
  NonNullable<
    ActiveSlackUserImportContextRefetchableQuery$data["organization"]
  >["slackUserImport"]
>

const ActiveSlackUserImportContext =
  createContext<ActiveSlackUserImportContextValue | null>(null)

export function useActiveSlackUserImportContext() {
  const context = useContext(ActiveSlackUserImportContext)
  if (!context)
    throw new Error(
      "useActiveSlackUserImportContext used outside of ActiveSlackUserImportProvider context"
    )
  return context
}

function ActiveSlackUserImportProvider({ children }: ActiveSlackUserImportProviderProps) {
  const activeOrganization = useActiveOrganization()!
  const [isPollStarted, setIsPollStarted] = useState(false)

  const [{ organization }, refetch] =
    Relay.useRefetchableQuery<ActiveSlackUserImportContextRefetchableQuery>(
      graphql`
        query ActiveSlackUserImportContextRefetchableQuery($id: ID!) {
          organization: node(id: $id) {
            ... on Organization {
              id
              slackUserImport {
                id
                status
              }
            }
          }
        }
      `,
      {
        id: activeOrganization.id,
      },
      { refetchInBackground: true, fetchPolicy: "network-only" }
    )
  // get the slackUserImport in a separate query so we don't poll the connection
  const { slackUserImport } =
    useLazyLoadQuery<ActiveSlackUserImportContext_PendingMatchesQuery>(
      graphql`
        query ActiveSlackUserImportContext_PendingMatchesQuery(
          $id: ID!
          $needsMapping: Boolean!
        ) {
          slackUserImport: node(id: $id) {
            ... on SlackUserImport {
              id
              pendingMatches: members(unmapped: true, status: pending)
                @include(if: $needsMapping) {
                __id
                totalCount
              }
            }
          }
        }
      `,
      {
        id: organization?.slackUserImport?.id ?? "",
        needsMapping: organization?.slackUserImport?.status === "needsmapping",
      },
      { fetchPolicy: "network-only" }
    )

  const connectionId = slackUserImport?.pendingMatches?.__id
  const pendingMatches = slackUserImport?.pendingMatches?.totalCount

  useInterval(
    () => {
      refetch({ id: activeOrganization.id })
    },
    2000,
    {
      shouldStartInterval: isPollStarted,
    }
  )

  const value = useMemo(() => {
    return {
      activeImport: organization?.slackUserImport ?? null,
      startPoll: () => setIsPollStarted(true),
      isPollStarted,
      pendingMatches,
      addMatch,
      removeMatch,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization?.slackUserImport, isPollStarted, pendingMatches])

  return (
    <ActiveSlackUserImportContext.Provider value={value}>
      {children}
    </ActiveSlackUserImportContext.Provider>
  )

  function addMatch() {
    commitLocalUpdate(RelayEnvironment, (store) => {
      if (!connectionId || typeof pendingMatches !== "number") return
      store.get(connectionId)?.setValue(pendingMatches + 1, "totalCount")
    })
  }
  function removeMatch() {
    commitLocalUpdate(RelayEnvironment, (store) => {
      if (!connectionId || typeof pendingMatches !== "number") return
      store.get(connectionId)?.setValue(pendingMatches - 1, "totalCount")
    })
  }
}

export default Relay.withSkeleton({
  skeleton: SlackUserImportPageSkeleton,
  component: ActiveSlackUserImportProvider,
})
