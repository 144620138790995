import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { rgbToHex, Theme, useTheme } from "@material-ui/core"
import { randomInRange } from "@utils/number/numberUtils"
import { autorun, observable } from "mobx"
import { observer } from "mobx-react-lite"
import React, { useCallback, useContext, useEffect, useRef } from "react"
import ReactCanvasConfetti from "react-canvas-confetti"

/** Callback function to trigger confetti */
type ConfettiCannon = () => void

const ConfettiContext = React.createContext<ConfettiCannon>(() => null)

/** State of an individual request to fire confetti */
interface ConfettiRequest {
  fired: boolean
}

/** Provides a useConfettiCannon callback! */
export const ConfettiProvider = observer(({ children }) => {
  // Track each unique request to fire confetti.
  const { current: requests } = useRef(observable.array<ConfettiRequest>())
  const fireConfetti: ConfettiCannon = useCallback(() => {
    requests.push({ fired: false })
  }, [requests])

  // Fire confetti on Cmd + Ctrl + C
  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if (e.key === "c" && e.ctrlKey && e.metaKey) {
        fireConfetti()
      }
    }
    window.addEventListener("keydown", onKeydown)
    return () => window.removeEventListener("keydown", onKeydown)
  }, [fireConfetti])

  const classes = useStyles()
  const theme = useTheme()

  return (
    <ConfettiContext.Provider value={fireConfetti}>
      {/* 
         Only mount the canvas when we want to fire the cannon. 
         The canvas overlays the entire page and messes with FullStory analytics.
      */}
      {requests.length > 0 && (
        <ReactCanvasConfetti
          data-testid={"ConfettiCanvas"}
          className={classes.confetti}
          refConfetti={handleCanvasMount}
        />
      )}
      {children}
    </ConfettiContext.Provider>
  )

  function handleCanvasMount(confetti: confetti.CreateTypes | null) {
    // library calls back with null when unmounted
    if (!confetti) return
    // Once canvas is mounted, fire all existing confetti requests.
    // The autorun triggers any confetti requests that come
    // in during animation to also fire.
    const stopFiring = autorun(() => {
      for (const request of requests) {
        // Prevent duplicate fires.
        if (request.fired) return
        request.fired = true
        // Play a random confetti options
        const keys = Object.keys(CONFETTI_PLAYERS)
        const player = CONFETTI_PLAYERS[keys[Math.floor(Math.random() * keys.length)]]
        const waitForDecay = player(confetti, theme) || Promise.resolve()
        waitForDecay.finally(() => {
          requests.clear()
          stopFiring()
        })
      }
    })
  }
})

type ConfettiPlayer = (
  confetti: confetti.CreateTypes,
  theme: Theme
) => Promise<null> | null

/**
 * Define how each type of confetti cannnon is played
 */
const CONFETTI_PLAYERS: Record<string, ConfettiPlayer> = {
  /** A party popper realistic confetti style */
  popper: (confetti) => {
    const popperShot = (particleRatio: number, opts: confetti.Options) => {
      return confetti({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(400 * particleRatio),
      })
    }
    popperShot(0.25, {
      spread: 26,
      startVelocity: 55,
    })
    popperShot(0.2, {
      spread: 60,
    })
    popperShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    })
    popperShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    })
    return popperShot(0.1, {
      spread: 120,
      startVelocity: 45,
    })
  },
  /** Series of fireworks from all over the screen */
  fireworks: (confetti) => {
    const playFirework = (originXA: number, originXB: number) => {
      return confetti({
        startVelocity: 20,
        spread: 360,
        ticks: 60,
        particleCount: 150,
        origin: {
          x: randomInRange(originXA, originXB),
          y: Math.random() - 0.2,
        },
      })
    }
    return new Promise((resolve) => {
      let lastPromise: Promise<null> | null = null
      const id = setInterval(() => {
        playFirework(0.1, 0.3)
        lastPromise = playFirework(0.7, 0.9)
      }, 400)
      setTimeout(() => {
        clearInterval(id)
        resolve(lastPromise)
      }, 3000)
    })
  },
  /** Org primary color stream of shapes */
  primaryStream: (confetti, theme) => {
    const playStream = (angle: number, originX: number) => {
      return confetti({
        particleCount: 3,
        angle,
        spread: 55,
        origin: { x: originX },
        colors: [
          theme.palette.primary.main,
          rgbToHex(theme.palette.primary.light),
          rgbToHex(theme.palette.primary.hover ?? theme.palette.primary.main),
        ],
      })
    }
    return new Promise((resolve) => {
      let lastPromise: Promise<null> | null = null
      const id = setInterval(() => {
        playStream(60, 0)
        lastPromise = playStream(120, 1)
      }, 16)
      setTimeout(() => {
        clearInterval(id)
        resolve(lastPromise)
      }, 2500)
    })
  },
}

const useStyles = makeUseStyles((theme) => ({
  confetti: {
    position: "fixed",
    width: "100%",
    height: "100%",
    zIndex: theme.zIndex.confetti,
    pointerEvents: "none",
  },
}))

/** Get a callback to fire the confetti cannon! */
export function useConfettiCannon() {
  return useContext(ConfettiContext)
}
