/** A context that exposes some commonly used details about the current organization. */

import ContentModuleCompletionConfetti from "@/content-usage/modules/components/ContentModuleCompletionConfetti"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useLabel } from "@/core/context/LabelsContext"
import {
  ActiveProductContextFragment$data,
  ActiveProductContextFragment$key,
  ProductRole,
} from "@/core/context/__generated__/ActiveProductContextFragment.graphql"
import { ActiveProductContextQuery } from "@/core/context/__generated__/ActiveProductContextQuery.graphql"
import { ActiveProductContext_useProductSlugHistoryRedirect$key } from "@/core/context/__generated__/ActiveProductContext_useProductSlugHistoryRedirect.graphql"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { useProductSlug } from "@/core/route/util/routeUtils"
import { isViewingAsMember } from "@/product/util/hook/useInitViewAsMember"
import Relay from "@/relay/relayUtils"
import usePermissions from "@utils/hook/usePermissions"
import { Location } from "history"
import React, { useContext, useEffect, useRef, useState } from "react"
import { graphql, useFragment } from "react-relay"
import { generatePath, Redirect, Route, Switch, useLocation } from "react-router-dom"

export type ActiveProductContextValue = ActiveProductContextFragment$data & {
  typeLabel: string
  viewerRole: ProductRole | undefined
  viewerIsManagerOrInstructor: boolean
  isAdminViewingAsMember: boolean
  hasMemberGroups: boolean
  viewerPermissions: Set<string>
}

/** The context exposed for reading the active product */
const ActiveProductContext = React.createContext<ActiveProductContextValue | null>(null)

/** Context for internally updating global activeProduct state */
const ActiveProductGlobalContext = React.createContext<React.Dispatch<
  React.SetStateAction<ActiveProductContextFragment$key | null>
> | null>(null)

export function useActiveProduct() {
  return useContext(ActiveProductContext)
}

/** Provides the state and context value for useActiveProduct() */
export const ActiveProductStateProvider: React.FC = (props) => {
  const [productKey, setProductKey] = useState<ActiveProductContextFragment$key | null>(
    null
  )
  return (
    <ActiveProductGlobalContext.Provider value={setProductKey}>
      <ManualActiveProductProvider productKey={productKey}>
        {props.children}
      </ManualActiveProductProvider>
    </ActiveProductGlobalContext.Provider>
  )
}

/** Executes and refetches ActiveProductContextQuery to update context state */
export const ActiveProductQueryProvider: React.FC = (props) => {
  const activeOrganization = useActiveOrganization()
  const organizationSlug = activeOrganization?.slug || ""
  // Get the product slug from the URL, the reason we can't use `useParams` here is because the :productSlug value is not available outside of the product routes.
  const productSlug = useProductSlug() || ""
  const { authUser } = useAuthUser()

  const [{ product }, refetch] = Relay.useRefetchableQuery<ActiveProductContextQuery>(
    graphql`
      query ActiveProductContextQuery(
        $productSlug: String!
        $organizationSlug: String!
        $skip: Boolean!
      ) {
        product(slug: $productSlug, organizationSlug: $organizationSlug)
          @skip(if: $skip) {
          ...ActiveProductContextFragment
          ...ActiveProductContext_useProductSlugHistoryRedirect
            @arguments(slug: $productSlug)
        }
      }
    `,
    { organizationSlug, productSlug, skip: !productSlug || !organizationSlug },
    { fetchPolicy: "network-only" }
  )

  // Provide the product key to the higher level context provider
  const setActiveProductKey = useContext(ActiveProductGlobalContext)
  useEffect(() => {
    if (setActiveProductKey) {
      setActiveProductKey(product ?? null)
    }
  }, [product, setActiveProductKey])

  const hasRendered = useRef(false)

  // Refetch in background when the authUser changes
  useEffect(() => {
    if (!hasRendered.current) {
      hasRendered.current = true
      return
    }
    refetch(
      { organizationSlug, productSlug, skip: !productSlug || !organizationSlug },
      { inBackground: true }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser?.id, refetch])

  const slugHistoryRedirect = useProductSlugHistoryRedirect(product ?? null)

  return (
    <Switch>
      {slugHistoryRedirect && <Redirect to={slugHistoryRedirect} />}
      <Route>
        <ManualActiveProductProvider productKey={product!}>
          <ContentModuleCompletionConfetti />
          {props.children}
        </ManualActiveProductProvider>
      </Route>
    </Switch>
  )
}

export const ManualActiveProductProvider: React.FC<{
  productKey: ActiveProductContextFragment$key | null
}> = (props) => {
  const { productKey, children } = props

  const product = useFragment<ActiveProductContextFragment$key>(
    graphql`
      fragment ActiveProductContextFragment on Product {
        id
        name
        slug
        type
        status
        registrationAvailability
        startDate
        endDate
        startsAt
        endsAt
        cover
        description
        richEditorCheckoutDescription
        landingPageUrl
        creationDatetime
        isMembersAppVisibleToAll
        landingPage {
          mode
          metaTitle
          metaDescription
          metaImageUrl
        }
        typeTag {
          label
        }
        badge {
          id
          kind
          icon
          color
          emoji
          mediaUrl
          ...BadgeFragment
        }
        viewerMembership {
          id
          memberId
          role
        }
        curriculum {
          id
          showCompletedUsers
          ...ContentModuleUtils_useCurriculumModuleIdsFragment
          ...ContentModuleUtils_useOnCurriculumModuleCompletedFragment
        }
        memberGroups(kind: custom) {
          totalCount
        }
        nextOrLastOccurrence {
          id
          datetimeRange
        }
        dashboard {
          id
          layout
        }
        tmpAllowExternalRegistrations
        ...appLevelContextFragment
        ...useIsWaitingRoomEnabledActiveProductFragment
        ...ProductRegistrationButtonActiveProductFragment
        ...usePermissionsFragment
        ...useConnectedProductAppsActiveProductFragment
        ...ExperienceHeader_ProductFragment
        ...HeaderContent_ProductAppsFragment
        ...ProductBadgeFragment
      }
    `,
    productKey
  )
  let productTypeLabel = useLabel("experience").singular
  if (product && product.type !== "course") productTypeLabel = "Event"
  const viewerPermissions = usePermissions(product)
  const role = product?.viewerMembership?.role
  const isManagerOrInstructor = role === "manager" || role === "instructor"
  const viewingAsMember = isViewingAsMember()

  return (
    <ActiveProductContext.Provider
      value={
        product
          ? {
              ...product,
              typeLabel: product?.typeTag?.label ?? productTypeLabel,
              viewerRole: role,
              viewerIsManagerOrInstructor: isManagerOrInstructor && !viewingAsMember,
              isAdminViewingAsMember: isManagerOrInstructor && viewingAsMember,
              hasMemberGroups: Boolean(product?.memberGroups.totalCount),
              viewerPermissions,
            }
          : null
      }
    >
      {children}
    </ActiveProductContext.Provider>
  )
}

/** Get the Location to redirect to based on SlugHistory records */
export function useProductSlugHistoryRedirect(
  productKey: ActiveProductContext_useProductSlugHistoryRedirect$key | null
): Location | null {
  const location = useLocation()
  const currentSlug = useProductSlug()
  const product = useFragment<ActiveProductContext_useProductSlugHistoryRedirect$key>(
    graphql`
      fragment ActiveProductContext_useProductSlugHistoryRedirect on Product
      @argumentDefinitions(slug: { type: "String!" }) {
        slug
        historicalSlug(slug: $slug) {
          occurrenceId
        }
      }
    `,
    productKey
  )

  // Not on product route or on the current slug
  if (!product || !currentSlug || product.slug === currentSlug) return null

  // Handle redirects for historical slugs from a migrated community event.
  // The only routes using slugs for community events were the landing and
  // registration flows, so redirect those to the new event registration page.
  if (product.historicalSlug?.occurrenceId) {
    return {
      ...location,
      pathname: generatePath(ROUTE_NAMES.PRODUCT.EVENTS.REGISTRATION.ROOT, {
        productSlug: product.slug,
        occurrenceId: product.historicalSlug.occurrenceId,
      }),
    }
  }

  // Standard product redirects
  return {
    ...location,
    pathname: location.pathname.replace(`/p/${currentSlug}`, `/p/${product.slug}`),
  }
}
