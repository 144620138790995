import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { observable } from "mobx"
import { createContext, useContext, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"

const historyStack = observable.array<string>()

const HistoryStackContext = createContext(historyStack)

export function useHistoryStack() {
  return useContext(HistoryStackContext)
}

/**
 * Sets up the HistoryStack to react to changes to routes.
 * Should only use once at the RootApp level.
 */
export function useInitHistoryStack() {
  const activeProduct = useActiveProduct()
  const { authUser } = useAuthUser()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const stackLength = historyStack.length
    const ranGoBack = historyStack[stackLength - 2] === location.pathname

    if (ranGoBack) {
      historyStack.pop()
    } else if (history.action === "REPLACE") {
      historyStack.pop()
      historyStack.push(history.location.pathname)
    } else {
      historyStack.push(location.pathname)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    historyStack.replace([location.pathname])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProduct?.id, authUser?.id])
}
