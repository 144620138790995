import { AppWithContextProvidersQuery } from "@/core/root-app/__generated__/AppWithContextProvidersQuery.graphql"
import React, { ComponentType, FC, ReactNode } from "react"
import { PreloadedQuery } from "react-relay"

export type DefaultProviderProps = {
  children: ReactNode
}

export type PreloadedProviderProps = {
  queryRef: PreloadedQuery<AppWithContextProvidersQuery>
} & DefaultProviderProps
type ProviderComponent =
  | FC<{ children?: ReactNode }>
  | ComponentType<DefaultProviderProps>
  // Some third party providers like HelmetProvider doesn't require any children
  | ComponentType<{ children?: ReactNode }>
  | {
      Component: FC<PreloadedProviderProps>
      props: {
        queryRef: PreloadedQuery<AppWithContextProvidersQuery>
      }
    }

interface ContextProvidersProps {
  providers: ProviderComponent[]
}

/**
 * Render a series of components as children to avoid a nested mess.
 * <ComponentA>
 *  <ComponentB>
 *   {children}
 *  </ComponentB>
 * </ComponentA>
 */
const ContextProviders: React.FC<ContextProvidersProps> = (props) => {
  const { children, providers } = props
  return <>{renderNestedChildren(children, providers)}</>
}

function renderNestedChildren(
  children: React.ReactNode,
  components: ProviderComponent[]
) {
  if (components.length === 0) {
    return children
  }

  const Provider = components[0]

  if (typeof Provider === "object" && "Component" in Provider) {
    const { Component, props: componentProps } = Provider
    return (
      <Component {...componentProps}>
        {renderNestedChildren(children, components.slice(1))}
      </Component>
    )
  }

  return <Provider>{renderNestedChildren(children, components.slice(1))}</Provider>
}

export default ContextProviders
