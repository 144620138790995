import { PreloadedProviderProps } from "@/core/context/ContextProviders"
import { AuthUserContextRefreshQuery } from "@/core/context/__generated__/AuthUserContextRefreshQuery.graphql"
import { AppWithContextProvidersPreloadedQuery } from "@/core/root-app/AppWithContextProvidersQuery"
import {
  AppWithContextProvidersQuery,
  AppWithContextProvidersQuery$data,
} from "@/core/root-app/__generated__/AppWithContextProvidersQuery.graphql"
import RelayEnvironment from "@/relay/RelayEnvironment"
import Relay from "@/relay/relayUtils"
import { createContext, useCallback, useContext } from "react"
import { usePreloadedQuery } from "react-relay"
import { commitLocalUpdate, graphql } from "relay-runtime"

export type AuthUserData<T extends boolean = false> = T extends true
  ? NonNullable<AppWithContextProvidersQuery$data["viewer"]>
  : AppWithContextProvidersQuery$data["viewer"] | null
export type AuthUserContextValue<T extends boolean = false> = T extends true
  ? {
      authUser: AuthUserData<true>
      refreshAuthUser: () => Promise<AuthUserData<true>>
      updateAuthUserInStore: (user: Partial<AuthUserData<true>>) => void
    }
  : {
      authUser: AuthUserData
      refreshAuthUser: () => Promise<AuthUserData<true>>
      updateAuthUserInStore: (user: Partial<AuthUserData<true>>) => void
    }

const AuthUserContext = createContext<AuthUserContextValue | null>(null)

type useAuthUserOptions<T extends boolean> = {
  required: T
}

export function useAuthUser<T extends boolean = false>(options?: useAuthUserOptions<T>) {
  // @ts-expect-error
  const value: AuthUserContextValue<T> = useContext(AuthUserContext)
  if (!value) throw new Error("useAuthUser used outside of AuthUserProvider context")

  if (!value.authUser && options?.required) throw new Error("Missing authUser")
  return value
}

const AuthUserProvider = (props: PreloadedProviderProps) => {
  const { viewer } = usePreloadedQuery<AppWithContextProvidersQuery>(
    AppWithContextProvidersPreloadedQuery,
    props.queryRef
  )

  /**
   * Fetch the viewer query from graphql
   * Returns the latest viewer and updates the authUser in the relay store
   */
  const refreshAuthUser = useCallback(async () => {
    const user = await Relay.runQuery<AuthUserContextRefreshQuery>(
      graphql`
        query AuthUserContextRefreshQuery {
          viewer {
            ...AuthUserContextFragment @relay(mask: false)
          }
        }
      `,
      {}
    )
    if (!user?.viewer) throw new Error("Missing user")
    return user.viewer as AuthUserData<true>
  }, [])

  /**
   * Update the local authUser in the relay store.
   */
  const updateAuthUserInStore = useCallback((userData: Partial<AuthUserData<true>>) => {
    commitLocalUpdate(RelayEnvironment, (store) => {
      const viewerStore = store.getRoot().getLinkedRecord("viewer")
      if (!viewerStore) return
      Relay.deepUpdate(store, viewerStore, userData)
    })
  }, [])

  return (
    <AuthUserContext.Provider
      value={{
        authUser: viewer,
        refreshAuthUser,
        updateAuthUserInStore,
      }}
    >
      {props.children}
    </AuthUserContext.Provider>
  )
}

export default AuthUserProvider

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment AuthUserContextFragment on User {
    id
    timezone
    avatar
    email
    firstName
    lastName
    fullName
    bio
    cover
    creationDatetime
    ...ProfileAvatarWithDetailsFragment
    ...ProfileAvatarFragment
    ...UserflowContextAuthUserFragment
    isVerified
    hasAcceptedTerms
    registrationStatus
    calendarConnection {
      id
      platform
    }
    lastActiveOrganizationMembership {
      organization {
        primaryDomain
      }
    }
    authProviderId
  }
`
