import { useAuthUser } from "@/core/context/AuthUserContext"
import { initSentry, setUserContextForSentry } from "@/core/sentryHandler"
import Relay from "@/relay/relayUtils"
import { useEffect, useRef } from "react"

export default function useInitSentry(hasConsented: boolean) {
  const { authUser } = useAuthUser()
  const initialized = useRef(false)

  useEffect(() => {
    // initialize Sentry if the user has consented to cookies
    if (!hasConsented) return
    try {
      if (!initialized.current) {
        initSentry()
        initialized.current = true
      }
      // if auth user changes, ensure we re-identify them
      if (authUser?.id) {
        setUserContextForSentry({ id: Relay.rawId(authUser.id) })
      }
    } catch (err) {
      // Don't crash the whole app if Sentry fails to initialize
      console.error(err)
    }
  }, [hasConsented, authUser?.id])
}
