import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { CustomThemeProviderActiveOrganizationFragment$key } from "@/core/context/__generated__/CustomThemeProviderActiveOrganizationFragment.graphql"
import {
  DEFAULT_DARK_THEME,
  DEFAULT_LIGHT_THEME,
  appTheme,
  createComputedTheme,
} from "@assets/style/appMuiTheme"
import { DiscoThemeProvider } from "@disco-ui"
import { CssBaseline } from "@material-ui/core"
import { isProduction } from "@utils/globalVariables"
import React, { FC, useEffect, useMemo, useState } from "react"
import { Helmet } from "react-helmet-async"
import { graphql, useFragment } from "react-relay"

export function useOrganizationTheme() {
  return useFragment<CustomThemeProviderActiveOrganizationFragment$key>(
    graphql`
      fragment CustomThemeProviderActiveOrganizationFragment on Organization {
        theme {
          primaryColor
          primaryContrastColor
          backgroundColor
          cardBackgroundColor
          navBackgroundColor
          kind
        }
      }
    `,
    useActiveOrganization()
  )
}

const CustomThemeProvider: FC = ({ children }) => {
  const organization = useOrganizationTheme()

  // Temporarily switch between dark/light theme in development by pressing Cmd + Ctrl + T
  const [isInverted, setIsInverted] = useState(false)
  useEffect(() => {
    if (isProduction()) return
    const onKeydown = (e: KeyboardEvent) => {
      if (e.key === "t" && e.ctrlKey && e.metaKey) {
        setIsInverted((v) => !v)
      }
    }
    window.addEventListener("keydown", onKeydown)
    return () => window.removeEventListener("keydown", onKeydown)
  }, [])

  const computedTheme = useMemo(() => {
    let customTheme = organization?.theme

    if (isInverted) {
      if (customTheme?.kind === "dark") {
        customTheme = DEFAULT_LIGHT_THEME
      } else {
        customTheme = DEFAULT_DARK_THEME
      }
    }

    // Force light mode if manually set in the DOM, but keep the existing
    // primary colour if it's set
    if (document.querySelector('meta[name="light-theme"]')) {
      customTheme = {
        ...DEFAULT_LIGHT_THEME,
        primaryColor: customTheme?.primaryColor || DEFAULT_LIGHT_THEME.primaryColor,
      }
    }

    // if the organization has a custom theme, use those settings and
    // compute other theme fields based on custom theme
    // otherwise, default to the Disco appTheme
    return createComputedTheme(customTheme)
  }, [organization?.theme, isInverted])

  return (
    <DiscoThemeProvider theme={computedTheme}>
      {/** Set the custom colors in the body element. This overrides the colors set in the index file */}
      <CssBaseline />
      <Helmet>
        {/* Safari header color */}
        <meta name={"theme-color"} content={computedTheme.palette.primary.main} />
      </Helmet>
      {children}
    </DiscoThemeProvider>
  )
}

export default CustomThemeProvider

export const withDiscoDefaultTheme: <TProps>(
  Component: React.FC<TProps>
) => (props: TProps) => JSX.Element = (Component) => (props) => {
  return (
    <DiscoThemeProvider theme={appTheme}>
      <Component {...props} />
    </DiscoThemeProvider>
  )
}

export const DefaultLightThemeProvider: React.FC = ({ children }) => {
  const computedTheme = useMemo(() => createComputedTheme(DEFAULT_LIGHT_THEME), [])
  return <DiscoThemeProvider theme={computedTheme}>{children}</DiscoThemeProvider>
}
