import { graphql } from "relay-runtime"

// eslint-disable-next-line no-unused-expressions
export const AppWithContextProvidersPreloadedQuery = graphql`
  query AppWithContextProvidersQuery($domain: String!) {
    domain: domainMapping(domain: $domain) {
      id
      organization {
        ...ActiveOrganizationContextFragment @relay(mask: false)
      }
    }
    viewer {
      ...AuthUserContextFragment @relay(mask: false)
    }
  }
`
