import { useTheme } from "@material-ui/core/styles"

/**
 * Pretty simple, it's a red inline * to mark a form field required
 */
function RequiredAsterisk() {
  const theme = useTheme()
  return <span style={{ color: theme.palette.groovy.red[500] }}>{"*"}</span>
}

export default RequiredAsterisk
