/**
 * @generated SignedSource<<0bf7ef54bfcab7245540aa65485e068b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GlobalDrawerProvider_CommunityEventQuery$variables = {
  organizationSlug: string;
  slug?: string | null;
};
export type GlobalDrawerProvider_CommunityEventQuery$data = {
  readonly product: {
    readonly nextOrLastOccurrence: {
      readonly id: string;
    } | null;
  } | null;
};
export type GlobalDrawerProvider_CommunityEventQuery = {
  variables: GlobalDrawerProvider_CommunityEventQuery$variables;
  response: GlobalDrawerProvider_CommunityEventQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationSlug",
    "variableName": "organizationSlug"
  },
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Occurrence",
  "kind": "LinkedField",
  "name": "nextOrLastOccurrence",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GlobalDrawerProvider_CommunityEventQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "product",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GlobalDrawerProvider_CommunityEventQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "product",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c9f825c0a55149b171393756a9c71095",
    "id": null,
    "metadata": {},
    "name": "GlobalDrawerProvider_CommunityEventQuery",
    "operationKind": "query",
    "text": "query GlobalDrawerProvider_CommunityEventQuery(\n  $organizationSlug: String!\n  $slug: String\n) {\n  product(organizationSlug: $organizationSlug, slug: $slug) {\n    nextOrLastOccurrence {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fcb7dc2c22c124842f8da491581f1756";

export default node;
