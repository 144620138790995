import WarningIcon from "@/core/ui/disco/icons/color-icons/warning-light.svg"
import { DiscoButton } from "@disco-ui"
import DiscoFloatingBar from "@disco-ui/floating-bar/DiscoFloatingBar"
import { Theme, useMediaQuery } from "@material-ui/core"
import { useEffect, useRef, useState } from "react"

/** How often to check for an updated version */
const POLL_INTERVAL_MS = 1000 * 60 * 2
/** How long the 'snooze' should last */
const SNOOZE_TIMEOUT = 1000 * 60 * 5

function UpdatePrompt() {
  const initialVersion = useRef("")
  const [showPrompt, setShowPrompt] = useState(false)
  const [isSnoozed, setIsSnoozed] = useState(false)

  useEffect(() => {
    // Fetch immediate version for intitialVersion
    checkLatestVersion()
    // Check every 2 minutes for a new update.
    const intervalId = setInterval(checkLatestVersion, POLL_INTERVAL_MS)
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isXsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))

  return (
    <DiscoFloatingBar
      open={showPrompt}
      content={
        isXsScreen
          ? "A new version of the app is available."
          : "A new version of Disco is available. Save your changes and reload to update to the latest version."
      }
      icon={<WarningIcon />}
      onClose={() => snooze()}
      buttons={
        <DiscoButton testid={"UpdatePrompt"} onClick={() => location.reload()}>
          {"Update Now"}
        </DiscoButton>
      }
    />
  )

  async function checkLatestVersion() {
    try {
      if (!S3_VERSION_CHECK_BUCKET) return
      if (isSnoozed) return
      const res = await fetch(
        `https://${S3_VERSION_CHECK_BUCKET}.s3.amazonaws.com/version.html`,
        {
          method: "HEAD",
        }
      )

      const isInMaintenanceMode = res.headers.get("x-amz-meta-maintenance")
      if (isInMaintenanceMode === "true") {
        // Do a full refresh to force people out of the app
        window.location.href = BASE_DOMAIN_URL
        return
      }
      const version = res.headers.get("x-amz-meta-version")
      if (!version) return
      if (!initialVersion.current) {
        initialVersion.current = version
        return
      }
      if (initialVersion.current !== version) {
        setShowPrompt(true)
      }
    } catch (err) {
      // Don't alert to Sentry. No user impact and nothing we can do for random network failures.
      console.error(`UpdatePrompt failed checking version: ${err}`)
    }
  }

  function snooze() {
    setIsSnoozed(true)
    setShowPrompt(false)
    setTimeout(() => {
      setIsSnoozed(false)
      setShowPrompt(true)
    }, SNOOZE_TIMEOUT)
  }
}

export default UpdatePrompt
