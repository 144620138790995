/**
 * @generated SignedSource<<5d85782dcce54a4cfa50bccf9233c702>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
export type DashboardLayout = "one_column" | "two_column" | "%future added value";
export type OrganizationCurrency = "usd" | "cad" | "eur" | "aud" | "gbp" | "%future added value";
export type OrganizationRole = "owner" | "admin" | "member" | "%future added value";
export type OrganizationVisibility = "public" | "private" | "%future added value";
export type SlackConnectionStatus = "active" | "inactive" | "auth_error" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ActiveOrganizationContextFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly slug: string;
  readonly timezone: string;
  readonly currency: OrganizationCurrency;
  readonly cover: string | null;
  readonly logo: string | null;
  readonly darkLogo: string | null;
  readonly faviconUrl: string | null;
  readonly description: string | null;
  readonly hasZoomIntegration: boolean;
  readonly hasStripeConnection: boolean;
  readonly hasZapierIntegration: boolean | null;
  readonly hasSlackConnection: boolean | null;
  readonly slackConnectionStatus: SlackConnectionStatus | null;
  readonly slackConnection: {
    readonly version: number;
  } | null;
  readonly stripeAccountId: string | null;
  readonly isDmEnabled: boolean;
  readonly isChannelsEnabled: boolean;
  readonly isProductsEnabled: boolean;
  readonly isExploreProductTabHidden: boolean;
  readonly isEventsEnabled: boolean;
  readonly showGlobalTabTitles: boolean;
  readonly primaryDomain: string;
  readonly billingContactEmail: string | null;
  readonly visibility: OrganizationVisibility;
  readonly googleAnalyticsId: string | null;
  readonly facebookPixelId: string | null;
  readonly googleTagManagerId: string | null;
  readonly creationDatetime: string;
  readonly viewerMembership: {
    readonly id: string;
    readonly role: OrganizationRole;
    readonly hasInstructorOrManagerRole: boolean;
    readonly organizationMembershipSlackConnection: {
      readonly id: string;
    } | null;
    readonly hasSeenOnboarding: boolean;
    readonly streamChatUserId: string | null;
  } | null;
  readonly badge: {
    readonly kind: BadgeKind;
    readonly icon: string | null;
    readonly color: string | null;
    readonly emoji: string | null;
    readonly mediaUrl: string | null;
  } | null;
  readonly dashboard: {
    readonly id: string;
    readonly layout: DashboardLayout;
  };
  readonly defaultMembershipPlan: {
    readonly id: string;
  };
  readonly viewerMembershipPlan: {
    readonly id: string;
    readonly viewerCanChangeMembershipPlan: {
      readonly value: boolean;
      readonly message: string;
    };
  };
  readonly authProvider: {
    readonly connectionId: string;
    readonly allowsJitProvisioning: boolean;
  } | null;
  readonly isMemberOnboardingRequired: boolean;
  readonly isChatBotEnabled: boolean;
  readonly hasBackfilledStreamMessages: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"CommunityBadgeFragment" | "useHasEntitlementActiveOrganizationFragment" | "CustomThemeProviderActiveOrganizationFragment" | "usePermissionsFragment" | "useCommunityLandingPagesFragment" | "LabelsContextActiveOrganizationFragment" | "useSubscriptionStandingActiveOrganizationFragment" | "UserflowContextActiveOrganizationFragment" | "useSearchClientFragment" | "useChatBotFragment" | "useCopilotBotFragment">;
  readonly " $fragmentType": "ActiveOrganizationContextFragment";
};
export type ActiveOrganizationContextFragment$key = {
  readonly " $data"?: ActiveOrganizationContextFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActiveOrganizationContextFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActiveOrganizationContextFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "darkLogo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "faviconUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasZoomIntegration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasStripeConnection",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasZapierIntegration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasSlackConnection",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slackConnectionStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SlackConnection",
      "kind": "LinkedField",
      "name": "slackConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "version",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stripeAccountId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDmEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isChannelsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isProductsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isExploreProductTabHidden",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isEventsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showGlobalTabTitles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryDomain",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingContactEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "googleAnalyticsId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "facebookPixelId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "googleTagManagerId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creationDatetime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationMembership",
      "kind": "LinkedField",
      "name": "viewerMembership",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasInstructorOrManagerRole",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationMembershipSlackConnection",
          "kind": "LinkedField",
          "name": "organizationMembershipSlackConnection",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasSeenOnboarding",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streamChatUserId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mediaUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommunityBadgeFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Dashboard",
      "kind": "LinkedField",
      "name": "dashboard",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "layout",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "defaultMembershipPlan",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "viewerMembershipPlan",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ChangeMembershipPlan",
          "kind": "LinkedField",
          "name": "viewerCanChangeMembershipPlan",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "message",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuthProvider",
      "kind": "LinkedField",
      "name": "authProvider",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "connectionId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowsJitProvisioning",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMemberOnboardingRequired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isChatBotEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasBackfilledStreamMessages",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useHasEntitlementActiveOrganizationFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CustomThemeProviderActiveOrganizationFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCommunityLandingPagesFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LabelsContextActiveOrganizationFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSubscriptionStandingActiveOrganizationFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserflowContextActiveOrganizationFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSearchClientFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useChatBotFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCopilotBotFragment"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "e0838085e463f77c6ead0cdacd1f17bf";

export default node;
