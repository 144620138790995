import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { useProductSlug } from "@/core/route/util/routeUtils"
import {
  MembershipSessionProvider_recordSessionLocationMutation,
  StateMembershipSessionActivityArea,
} from "@/core/session/__generated__/MembershipSessionProvider_recordSessionLocationMutation.graphql"
import useDebounce from "@utils/hook/useDebounce"
import useInterval from "@utils/hook/useInterval"
import { observer } from "mobx-react-lite"
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router"
import { graphql } from "relay-runtime"

const SESSION_INACTIVITY_TIME_SECONDS = 10 * 60 // 10 minutes in seconds
const SESSION_HEARTBEAT_INTERVAL_MS = 60 * 1000 // one minute in miliseconds

interface MembershipSessionProviderProps {
  children: ReactNode
}

function MembershipSessionProvider({ children }: MembershipSessionProviderProps) {
  const location = useLocation()
  const { authUser } = useAuthUser()
  const activeOrganization = useActiveOrganization()
  const activeProduct = useActiveProduct()
  const currentProductSlug = useProductSlug()

  /** Whether or not the user is actively using the platform */
  const [isActive, setIsActive] = useState<boolean>(true) // Is active on initial load

  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null)
  const inactivityCoundownRef = useRef<number>(SESSION_INACTIVITY_TIME_SECONDS)
  const masterPauseInactivityCountdownRef = useRef<boolean>(false)
  const areaRef = useRef<StateMembershipSessionActivityArea>(getAreaByPath())

  //* Initialize
  useEffect(() => {
    // Start the inactivity countdown
    startInactivityCountdown()

    // Add event listeners to keep track of user activity
    window.addEventListener("resize", debouncedRestartInactivityCountdown)
    document.addEventListener("visibilitychange", debouncedRestartInactivityCountdown)

    return () => {
      window.removeEventListener("resize", debouncedRestartInactivityCountdown)
      document.removeEventListener(
        "visibilitychange",
        debouncedRestartInactivityCountdown
      )

      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Update + Record location if we change areas
  useEffect(() => {
    // Update the area if we change paths
    const previousArea = areaRef.current
    const currentArea = getAreaByPath()
    areaRef.current = currentArea

    const shouldRecordLocation = checkShouldRecordLocation()
    if (!shouldRecordLocation) return

    if (previousArea === currentArea) return // No need to log if we are in the same area
    recordSessionLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  // Record location if we change products
  useEffect(() => {
    const shouldRecordLocation = checkShouldRecordLocation()
    if (!shouldRecordLocation) return

    recordSessionLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProduct?.id])

  // Record location every minute
  useInterval(() => {
    const shouldRecordLocation = checkShouldRecordLocation()
    if (!shouldRecordLocation) return

    recordSessionLocation()
  }, SESSION_HEARTBEAT_INTERVAL_MS)

  const recordSessionLocationForm =
    useFormStore<MembershipSessionProvider_recordSessionLocationMutation>(
      graphql`
        mutation MembershipSessionProvider_recordSessionLocationMutation(
          $input: RecordSessionLocationInput!
        ) {
          response: recordSessionLocation(input: $input) {
            errors {
              field
              message
            }
          }
        }
      `,
      {
        area: getAreaByPath(),
        productId: activeProduct?.id,
      },
      {
        showErrorToast: false,
      }
    )

  const debouncedRestartInactivityCountdown = useDebounce(() => {
    restartInactivityCountdown()
  }, 500)

  return (
    <MembershipSessionContext.Provider
      value={{
        pauseInactivityCountdown,
        resumeInactivityCountdown,
      }}
    >
      <span
        onMouseMove={debouncedRestartInactivityCountdown}
        onClick={debouncedRestartInactivityCountdown}
        onKeyDown={debouncedRestartInactivityCountdown}
        onFocus={debouncedRestartInactivityCountdown}
        onBlur={debouncedRestartInactivityCountdown}
        onTouchStart={debouncedRestartInactivityCountdown}
        role={"button"}
        tabIndex={0}
      >
        {children}
      </span>
    </MembershipSessionContext.Provider>
  )

  function recordSessionLocation() {
    recordSessionLocationForm.submit({
      area: getAreaByPath(),
      productId: activeProduct?.id,
    })
  }

  // Clears the countdown interval
  function stopInactivityCountdown() {
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current)
      countdownIntervalRef.current = null
    }
  }

  // Clears and then starts a new countdown interval
  function startInactivityCountdown() {
    setIsActive(true)

    // Clear the previous interval before starting the new one
    stopInactivityCountdown()

    countdownIntervalRef.current = setInterval(() => {
      if (masterPauseInactivityCountdownRef.current) return

      inactivityCoundownRef.current -= 1

      // If the countdown reaches 0, set their state to inactive
      if (inactivityCoundownRef.current <= 0) {
        setIsActive(false)
        stopInactivityCountdown()
      }
    }, 1000)

    inactivityCoundownRef.current = SESSION_INACTIVITY_TIME_SECONDS
  }

  // Restarts the counter while keeping the countdown interval running
  function restartInactivityCountdown() {
    if (masterPauseInactivityCountdownRef.current) return

    // Don't need to restart countdown if the interval doesn't exist
    if (countdownIntervalRef.current) {
      inactivityCoundownRef.current = SESSION_INACTIVITY_TIME_SECONDS
    } else {
      startInactivityCountdown()
    }
  }

  function getAreaByPath(): StateMembershipSessionActivityArea {
    const curriculumRegex = new RegExp(
      `^${ROUTE_NAMES.PRODUCT.CURRICULUM.ROOT.replace(/:[^/]+/g, "([^/]+)")}`
    )
    if (curriculumRegex.test(location.pathname)) return "curriculum"

    if (activeProduct) return "product" // If inside product, default to product
    return "community" // If not inside product, default to community
  }

  function pauseInactivityCountdown() {
    masterPauseInactivityCountdownRef.current = true
  }

  function resumeInactivityCountdown() {
    masterPauseInactivityCountdownRef.current = false
    debouncedRestartInactivityCountdown()
  }

  function currentProductSlugMatchesActiveProductSlug() {
    if (!currentProductSlug) return true
    return currentProductSlug === activeProduct?.slug
  }

  function checkShouldRecordLocation() {
    if (!authUser) return false
    if (!activeOrganization) return false
    if (!activeOrganization.viewerMembership) return false
    if (!isActive) return false
    if (!currentProductSlugMatchesActiveProductSlug()) return false // If we are on product route, make sure activeProduct context has loaded or else the correct productId won't get sent

    return true
  }
}

type MembershipSessionContextValue = {
  pauseInactivityCountdown: () => void
  resumeInactivityCountdown: () => void
}

const MembershipSessionContext = createContext<MembershipSessionContextValue | null>(null)

export const useSession = (): MembershipSessionContextValue => {
  const context = useContext(MembershipSessionContext)
  return context!
}

export default observer(MembershipSessionProvider)
