import { ValidationError } from "@/relay/RelayTypes"
import {
  displayErrorToast,
  displayRestfulErrorToast,
} from "@components/toast/ToastProvider"

namespace RestfulUtil {
  export async function handleStream(
    stream: ReadableStream<Uint8Array>,
    cb: (decodedChunk: string) => boolean,
    dataHandlers?: {
      onStart?: (data: any) => void
      onData?: (data: any) => void
      onEnd?: (data: any) => void
    }
  ) {
    const reader = stream.getReader()
    const decoder = new TextDecoder()

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { value, done } = await reader.read()
      if (done) break

      let decodedChunk: string = decoder
        .decode(value, { stream: true })
        .replaceAll("\n", "") // replace new lines with empty string, these are the new lines that are appended to each chunk

      const firstStreamedChunk = isFirstStreamedChunk(decodedChunk)
      if (firstStreamedChunk) {
        const [data, secondChunk] = decodedChunk.split("/startStream: ") // First chunk could look like "{ some: data } /startStream: blah blah"
        decodedChunk = secondChunk
        try {
          dataHandlers?.onStart?.(JSON.parse(data))
        } catch (e) {
          throw new Error("Failed to parse start stream chunk")
        }
      }

      const lastStreamedChunk = isLastStreamedChunk(decodedChunk)
      if (lastStreamedChunk) {
        const [secondLast, data] = decodedChunk.split("/endStream: ") // Last chunk could look like "blah blah /endStream: { some: data }"
        decodedChunk = secondLast

        try {
          dataHandlers?.onEnd?.(JSON.parse(data))
        } catch (e) {
          throw new Error("Failed to parse end stream chunk")
        }
      }

      // Handle data chunk
      if (isDataChunk(decodedChunk)) {
        // Create a regexp to catch anything between /startData and /endData
        const dataChunkRegex = /\/startData(.*?)\/endData/g
        const dataChunks = decodedChunk.match(dataChunkRegex)

        for (const dataChunk of dataChunks || []) {
          // Remove the data chunk from the decoded chunk
          decodedChunk = decodedChunk.replace(dataChunk, "")

          // Get the data between /startData and /endData
          const data = dataChunk.replace("/startData", "").replace("/endData", "")
          if (!data) continue

          try {
            dataHandlers?.onData?.(JSON.parse(data))
          } catch (e) {
            throw new Error(
              `Failed to parse data chunk: data: ${JSON.stringify(data)}\n error: ${e}`
            )
          }
        }
      }

      const result = cb(
        decodedChunk.replaceAll("{{{ newline }}}", "\n") // replace {{{ newline }}} with actual newlines, these are the new lines that are in the original text
      )
      if (result === false) {
        reader.cancel()
        break
      }
    }
  }

  export function hasError(response: Response) {
    if ([500, 400, 403].includes(response.status)) {
      return true
    }
    return false
  }

  export async function getValidationError(
    response: Response
  ): Promise<ValidationError | null> {
    if (!hasError(response)) return null
    const error = await response.json()
    if (!error)
      return {
        field: "*",
        message: "Something went wrong, please try again later",
      }
    if (typeof error === "string")
      return {
        field: "*",
        message: error,
      }
    if (error.message)
      return {
        field: error.field ?? "*",
        message: error.message,
      }
    const { errors } = error
    if (errors?.length && errors[0]?.message)
      return {
        field: errors[0].field ?? "*",
        message: errors[0].message,
      }
    return {
      field: "*",
      message: "Something went wrong, please try again later",
    }
  }

  export function isFirstStreamedChunk(chunk: string) {
    return chunk.includes("/startStream")
  }

  export function isLastStreamedChunk(chunk: string) {
    return chunk.includes("/endStream: ")
  }

  export function isDataChunk(chunk: string) {
    return chunk.includes("/startData") && chunk.includes("/endData")
  }

  export async function createStream({
    startStreamCb,
    handleText,
    setStatus,
    onBeforeStart,
    cancelledRef,
    showErrorToasts = true,
  }: {
    startStreamCb: (signal: AbortSignal) => Promise<Response>
    handleText: (text: string) => boolean
    setStatus?: (status: "loading" | "error" | "success" | null) => void
    onBeforeStart?: VoidFunction
    onStreamEnd?: (data: any) => void
    cancelledRef?: { current: boolean }
    showErrorToasts?: boolean
  }) {
    const controller = new AbortController()
    setStatus?.("loading")
    onBeforeStart?.()
    const response = await startStreamCb(controller.signal)

    if (RestfulUtil.hasError(response)) {
      setStatus?.("error")
      if (showErrorToasts) await displayRestfulErrorToast(response)
      return
    }

    if (!response.ok || !response.body) {
      setStatus?.("error")
      if (showErrorToasts)
        displayErrorToast("An unexpected error occurred, please try again.")
      return
    }

    let generatedText = ""
    await RestfulUtil.handleStream(response.body!, (decodedChunk) => {
      if (cancelledRef?.current === true) return false
      generatedText += decodedChunk
      return handleText(generatedText)
    })

    const result = generatedText.trim()
    if (result) {
      setStatus?.("success")
    } else {
      setStatus?.("error")
      if (showErrorToasts) displayErrorToast("Failed to generate, please try again.")
      controller.abort()
    }

    return result
  }
}

export default RestfulUtil
