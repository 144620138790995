import { SECOND_IN_MS } from "@utils/time/timeConstants"
import axios, { AxiosError, AxiosRequestConfig } from "axios"

const MAX_ATTEMPT = 10

export class CancelError extends Error {
  constructor(message: string) {
    super(message)

    Object.defineProperty(this, "name", {
      value: "CancelError",
    })
  }
}

export class MaxRetryAttemptReachedError extends Error {
  constructor(message: string) {
    super(message)

    Object.defineProperty(this, "name", {
      value: "MaxRetryLimitReachedError",
    })
  }
}

/** Custom axios instance with a retry pattern. It retries network errors for up to 10 times before returning the error */
const axiosWithRetry = axios.create()

/**
 * @param config AxiosRequestConfig
 * @returns number - remaining attempt
 */
const getRemainingAttempt = (config: AxiosRequestConfig<unknown>) => {
  if (!hasRemainingAttempt(config)) return MAX_ATTEMPT
  return config.remainingAttempt
}

/**
 * Determine if an error is retryable
 *
 * @param err AxiosError
 * @returns boolean
 */
const isRetryableError = (err: AxiosError) => {
  // Only retry on network errors
  return err.message === "Network Error"
}

/**
 * Check if a request has been previously retried
 *
 * @param config AxiosRequestConfig
 * @returns boolean
 */
const hasRemainingAttempt = (
  config: AxiosRequestConfig<unknown>
): config is AxiosRequestConfig & { remainingAttempt: number } => {
  return "remainingAttempt" in config
}

axiosWithRetry.interceptors.response.use(
  (response) => response,
  (error) => {
    // Do nothing if the request was cancelled
    if (axios.isCancel(error)) return Promise.reject(error)
    // Non-retryable errors
    if (!axios.isAxiosError(error)) return Promise.reject(error)
    if (!error.config) return Promise.reject(error)

    const { config } = error
    // Get the remaining attempt from the error and calculate the delay time (linear backoff)
    const remainingAttempt = getRemainingAttempt(config)
    const delay = (MAX_ATTEMPT - remainingAttempt + 1) * SECOND_IN_MS * 2

    if (isRetryableError(error) && remainingAttempt > 0) {
      // @ts-ignore: Set the remaining attempt for the next request
      config.remainingAttempt = remainingAttempt - 1
      return new Promise((resolve) =>
        setTimeout(() => resolve(axiosWithRetry.request(config)), delay)
      )
    }

    // All retry attempts failed
    return Promise.reject(
      new MaxRetryAttemptReachedError(
        "The upload was unsuccessful, please check your connection and try again."
      )
    )
  }
)

export default axiosWithRetry
