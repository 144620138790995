import { useAuthUser } from "@/core/context/AuthUserContext"
import { useCookieConsent } from "@/organization/tracking/cookie-consent/CookieConsentStore"
import { useTrackingTransparency } from "@/product/util/hook/useTrackingTransparency"
import Relay from "@/relay/relayUtils"
import * as FS from "@fullstory/browser"
import React, { useEffect, useRef } from "react"

const FullStoryContext = React.createContext(false)

const FullStoryProvider: React.FC = ({ children }) => {
  const { authUser } = useAuthUser()
  const { hasConsented } = useCookieConsent()
  const canTrack = useTrackingTransparency()
  const initialized = useRef(false)

  const shouldInitializeFullstory =
    !!FULLSTORY_ORG_ID &&
    !/headless/i.test(navigator.userAgent) &&
    hasConsented &&
    canTrack

  useEffect(() => {
    // initialize full story if the user has consented to cookies
    if (shouldInitializeFullstory && !initialized.current) {
      FS.init({ orgId: FULLSTORY_ORG_ID })
      initialized.current = true
    }
  }, [shouldInitializeFullstory])

  useEffect(() => {
    // if auth user changes, ensure we re-identify the auth-user
    if (authUser?.id && shouldInitializeFullstory) FS.identify(Relay.rawId(authUser.id))
  }, [authUser?.id, shouldInitializeFullstory])

  return (
    <FullStoryContext.Provider value={shouldInitializeFullstory}>
      {children}
    </FullStoryContext.Provider>
  )
}

export { FullStoryContext, FullStoryProvider }
