export default class Format {
  /** Format to a locale-aware currency/price value. */
  static asCurrency(
    value: number | string | null | undefined,
    opts: Intl.NumberFormatOptions = {}
  ) {
    const lang = navigator.language
    const curr = opts.currency?.toUpperCase()
    const formatter = new Intl.NumberFormat(lang, {
      style: "currency",
      currency: curr || "USD",
      ...opts,
    })

    return formatter
      .formatToParts(parseToNumber(value))
      .map(({ type, value: v }) => {
        switch (type) {
          case "currency":
            return curr === "CAD" && (lang === "en-CA" || lang === "fr-CA")
              ? `CA${v}`
              : `${v}`
          default:
            return v
        }
      })
      .reduce((string, part) => string + part)
  }

  /** Formats a percent value like 0.5 to 50% */
  static asPercent(value: number | string | null | undefined): string {
    // eslint-disable-next-line no-magic-numbers
    return `${Math.round(parseToNumber(value) * 100)}%`
  }
}
function parseToNumber(value: number | string | null | undefined): number {
  let parsedValue = value
  if (typeof parsedValue === "string") {
    parsedValue = parseFloat(parsedValue)
  }
  if (!parsedValue || isNaN(parsedValue)) {
    return 0
  }
  return parsedValue
}
