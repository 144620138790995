/**
 * @generated SignedSource<<caf21d288db4727c541423b62e4ab4d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
export type NotificationsContextQuery$variables = {
  id: string;
};
export type NotificationsContextQuery$data = {
  readonly node: {
    readonly viewerNotifications?: {
      readonly totalCount: number;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly productId: string | null;
          readonly readAt: string | null;
          readonly kind: string;
          readonly entityId: string;
          readonly content: {
            readonly type: ContentType;
            readonly post: {
              readonly feedId: string;
              readonly feed: {
                readonly app: {
                  readonly navFolderId: string | null;
                  readonly navSectionId: string | null;
                };
              };
            } | null;
          } | null;
          readonly submission: {
            readonly id: string;
            readonly contentUsageId: string;
            readonly contentUsage: {
              readonly productApp: {
                readonly navFolderId: string | null;
              } | null;
            };
          } | null;
          readonly webFormSubmission: {
            readonly id: string;
            readonly contentUsageId: string | null;
          } | null;
          readonly occurrence: {
            readonly appId: string | null;
          } | null;
        };
      }>;
    };
  } | null;
};
export type NotificationsContextQuery = {
  variables: NotificationsContextQuery$variables;
  response: NotificationsContextQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "isUnread",
    "value": true
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feedId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "navFolderId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "navSectionId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentUsageId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "WebFormSubmission",
  "kind": "LinkedField",
  "name": "webFormSubmission",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v13/*: any*/)
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsContextQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "NotificationNodeConnection",
                "kind": "LinkedField",
                "name": "viewerNotifications",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NotificationNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Notification",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Content",
                            "kind": "LinkedField",
                            "name": "content",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Post",
                                "kind": "LinkedField",
                                "name": "post",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Feed",
                                    "kind": "LinkedField",
                                    "name": "feed",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ProductApp",
                                        "kind": "LinkedField",
                                        "name": "app",
                                        "plural": false,
                                        "selections": [
                                          (v11/*: any*/),
                                          (v12/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentSubmission",
                            "kind": "LinkedField",
                            "name": "submission",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ContentUsage",
                                "kind": "LinkedField",
                                "name": "contentUsage",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductApp",
                                    "kind": "LinkedField",
                                    "name": "productApp",
                                    "plural": false,
                                    "selections": [
                                      (v11/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Occurrence",
                            "kind": "LinkedField",
                            "name": "occurrence",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "viewerNotifications(isUnread:true)"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationsContextQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "NotificationNodeConnection",
                "kind": "LinkedField",
                "name": "viewerNotifications",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NotificationNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Notification",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Content",
                            "kind": "LinkedField",
                            "name": "content",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Post",
                                "kind": "LinkedField",
                                "name": "post",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Feed",
                                    "kind": "LinkedField",
                                    "name": "feed",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ProductApp",
                                        "kind": "LinkedField",
                                        "name": "app",
                                        "plural": false,
                                        "selections": [
                                          (v11/*: any*/),
                                          (v12/*: any*/),
                                          (v4/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentSubmission",
                            "kind": "LinkedField",
                            "name": "submission",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ContentUsage",
                                "kind": "LinkedField",
                                "name": "contentUsage",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductApp",
                                    "kind": "LinkedField",
                                    "name": "productApp",
                                    "plural": false,
                                    "selections": [
                                      (v11/*: any*/),
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Occurrence",
                            "kind": "LinkedField",
                            "name": "occurrence",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "viewerNotifications(isUnread:true)"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8d7a059a25e5b6a019233c37d21cd967",
    "id": null,
    "metadata": {},
    "name": "NotificationsContextQuery",
    "operationKind": "query",
    "text": "query NotificationsContextQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Organization {\n      viewerNotifications(isUnread: true) {\n        totalCount\n        edges {\n          node {\n            id\n            productId\n            readAt\n            kind\n            entityId\n            content {\n              type\n              post {\n                feedId\n                feed {\n                  app {\n                    navFolderId\n                    navSectionId\n                    id\n                  }\n                  id\n                }\n                id\n              }\n              id\n            }\n            submission {\n              id\n              contentUsageId\n              contentUsage {\n                productApp {\n                  navFolderId\n                  id\n                }\n                id\n              }\n            }\n            webFormSubmission {\n              id\n              contentUsageId\n            }\n            occurrence {\n              appId\n              id\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b832176772a25e0ed801b2a71ad425de";

export default node;
