import MetaTitle from "@/main/page/MetaTitle"
import { appTheme } from "@assets/style/appMuiTheme"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoThemeProvider } from "@disco-ui"
import { Route, RouteProps } from "react-router-dom"

type UnStyledRouteProps = RouteProps & {
  metaTitle?: string
}

export default function UnStyledRoute(props: UnStyledRouteProps) {
  const { metaTitle, children, ...routeProps } = props
  const classes = useStyles()

  return (
    <>
      <MetaTitle>{metaTitle}</MetaTitle>
      {renderRoute()}
    </>
  )

  function renderRoute() {
    return (
      <Route {...routeProps}>
        <DiscoThemeProvider theme={appTheme}>
          <div className={classes.backgroundProvider}>{children}</div>
        </DiscoThemeProvider>
      </Route>
    )
  }
}

const useStyles = makeUseStyles({
  backgroundProvider: {
    backgroundColor: appTheme.palette.background.default,
    flexGrow: 1,
  },
})
