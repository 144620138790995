import { Route, Switch } from "react-router-dom"

type HiddenForRoutesProps = {
  path?: string[]
  exact?: boolean
}
function HiddenForRoutes({
  children,
  path,
  exact = true,
}: React.PropsWithChildren<HiddenForRoutesProps>) {
  return (
    <Switch>
      <Route path={path} exact={exact} component={() => null} />
      <Route>{children}</Route>
    </Switch>
  )
}

export default HiddenForRoutes
