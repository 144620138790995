/**
 * @generated SignedSource<<69cd0f25994cf53228c0fcab3925f1c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SlackUserImportStatus = "initial" | "needsmapping" | "inprogress" | "completed" | "failed" | "cancelled" | "%future added value";
export type ActiveSlackUserImportContextRefetchableQuery$variables = {
  id: string;
};
export type ActiveSlackUserImportContextRefetchableQuery$data = {
  readonly organization: {
    readonly id?: string;
    readonly slackUserImport?: {
      readonly id: string;
      readonly status: SlackUserImportStatus;
    } | null;
  } | null;
};
export type ActiveSlackUserImportContextRefetchableQuery = {
  variables: ActiveSlackUserImportContextRefetchableQuery$variables;
  response: ActiveSlackUserImportContextRefetchableQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "SlackUserImport",
  "kind": "LinkedField",
  "name": "slackUserImport",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActiveSlackUserImportContextRefetchableQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActiveSlackUserImportContextRefetchableQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "83c0719026ba824175998000dfca9632",
    "id": null,
    "metadata": {},
    "name": "ActiveSlackUserImportContextRefetchableQuery",
    "operationKind": "query",
    "text": "query ActiveSlackUserImportContextRefetchableQuery(\n  $id: ID!\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      id\n      slackUserImport {\n        id\n        status\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6cef12b3f9e2d528bb3796a4dfc88acc";

export default node;
