import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { GlobalDrawerParams, useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import {
  LiveEventBarQuery,
  LiveEventBarQuery$data,
} from "@/core/context/__generated__/LiveEventBarQuery.graphql"
import ROUTE_NAMES, { routeValues } from "@/core/route/util/routeNames"
import { isOccurrenceLive } from "@/occurrence/util/occurrenceUtils"
import OccurrenceRSVPAttendanceStatus from "@/organization/occurrence/OccurrenceRSVPAttendanceStatus"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import PulsatingDot from "@components/pulsating-dot/PulsatingDot"
import { DiscoButton, DiscoText } from "@disco-ui"
import DiscoFloatingBar from "@disco-ui/floating-bar/DiscoFloatingBar"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useInterval from "@utils/hook/useInterval"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import { addDays } from "date-fns"
import { useCallback, useEffect, useState } from "react"
import { graphql, useSubscribeToInvalidationState } from "react-relay"
import { generatePath, useLocation } from "react-router-dom"

type LiveOccurrence = {
  occurrence: NonNullable<
    NonNullable<LiveEventBarQuery$data["organization"]>["occurrences"]
  >["edges"][0]["node"]
  dismissed: boolean
}
type LiveOccurrenceMap = { [id: GlobalID]: LiveOccurrence }

const CHECK_LIVE_INTERVAL = 1000 * 60 // 1 minute
const REFRESH_EVENTS_INTERVAL = 1000 * 60 * 60 * 24 // 24 hours
const FIRST_STARTS_BEFORE = addDays(new Date(), 1).toISOString()

function LiveEventBar({ testid = "LiveEventBar" }: TestIDProps) {
  const [liveOccurrenceMap, setLiveOccurrenceMap] = useState<LiveOccurrenceMap>({})
  const activeOrganization = useActiveOrganization()
  const activeProduct = useActiveProduct()
  const location = useLocation()
  const isMobile = useIsMobile()
  const classes = useStyles()
  const drawer = useGlobalDrawer("event")

  const [{ organization }, refetch] = Relay.useRefetchableQuery<LiveEventBarQuery>(
    graphql`
      query LiveEventBarQuery($id: ID!, $startsBefore: DateTime!) {
        organization: node(id: $id) {
          ... on Organization {
            occurrences(
              first: null
              datetimeFilter: upcoming
              startsBefore: $startsBefore
            ) @connection(key: "LiveEventBar__occurrences") {
              __id
              edges {
                node {
                  id
                  name
                  datetimeRange
                  product {
                    name
                  }
                  ...OccurrenceRSVPAttendanceStatusFragment
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization?.id || "",
      startsBefore: FIRST_STARTS_BEFORE,
    }
  )
  // if a new occurrence is added (or the list is invalidated for any other reason), refetch the updated list
  useSubscribeToInvalidationState(
    organization?.occurrences ? [organization.occurrences.__id] : [],
    () => {
      if (organization?.occurrences)
        refetch({
          id: activeOrganization?.id || "",
          startsBefore: addDays(new Date(), 1).toISOString(),
        })
    }
  )
  const upcomingOccurrences = Relay.connectionToArray(organization?.occurrences)

  const liveOccurrences = Object.values(liveOccurrenceMap).filter((o) => !o.dismissed)
  const count = liveOccurrences.length
  const singleLiveEvent = count === 1 ? liveOccurrences[0].occurrence : undefined
  const multipleLiveEvents = count > 1

  // Should dismiss bar once live event is viewed on an 'upcoming' events page
  const isOnDismissRoute = useCallback(() => {
    if (activeProduct) {
      return location.pathname.includes(
        generatePath(ROUTE_NAMES.PRODUCT.EVENTS.LIST.UPCOMING, {
          productSlug: activeProduct.slug,
        })
      )
    }
    return (
      location.pathname.includes(ROUTE_NAMES.COMMUNITY.EVENTS_CALENDAR.ROOT) ||
      location.pathname === ROUTE_NAMES.COMMUNITY.EVENTS_CALENDAR.ROOT
    )
  }, [location, activeProduct])

  const dismiss = useCallback((occurrenceId?: GlobalID) => {
    setLiveOccurrenceMap((prev) => {
      const newMap: LiveOccurrenceMap = {}
      Object.values(prev).forEach((o) => {
        if (occurrenceId && occurrenceId !== o.occurrence.id) {
          newMap[o.occurrence.id] = o
        } else {
          newMap[o.occurrence.id] = { ...o, dismissed: true }
        }
      })
      return newMap
    })
  }, [])

  useInterval(
    () => {
      if (shouldHideBar()) return
      // store the full list of occurrences that are live
      setLiveOccurrenceMap((prev) =>
        upcomingOccurrences.reduce<LiveOccurrenceMap>((formatted, o) => {
          const isLive = isOccurrenceLive(o.datetimeRange)
          if (isLive)
            formatted[o.id] = {
              occurrence: o,
              // if the user has previous dismissed the bar for live event(s), maintain that state
              // if we load on a page where bar should be dismissed, do for the events live at that moment
              dismissed: prev[o.id] ? prev[o.id].dismissed : isOnDismissRoute(),
            }
          return formatted
        }, {})
      )
      //  re-evaluate which occurrences are live every minute
    },
    CHECK_LIVE_INTERVAL,
    { shouldRunCallbackAtStart: true }
  )

  // Refresh the results
  useInterval(() => {
    refetch({
      id: activeOrganization?.id || "",
      startsBefore: addDays(new Date(), 1).toISOString(),
    })
  }, REFRESH_EVENTS_INTERVAL)

  useEffect(() => {
    // Dismiss the bar for an occurrence once viewed in drawer.
    if (drawer.params.drawerOccurrenceId) {
      dismiss(drawer.params.drawerOccurrenceId)
    }
    // Dismiss the bar for all live if viewed in the 'upcoming' page.
    if (isOnDismissRoute()) {
      dismiss()
    }
  }, [drawer.params.drawerOccurrenceId, isOnDismissRoute, dismiss])

  if (shouldHideBar()) return null

  const content = singleLiveEvent ? (
    <DiscoText truncateText={2} testid={"LiveEventBar.text-content"}>
      <DiscoText display={"inline"} truncateText={1}>
        {singleLiveEvent.name}
      </DiscoText>
      {` is live${isMobile ? "" : " right now"} in '${singleLiveEvent.product.name}'.`}
    </DiscoText>
  ) : multipleLiveEvents ? (
    `${count} events are live${isMobile ? "" : " right now"}. Check them out!`
  ) : (
    ""
  )

  return (
    <DiscoFloatingBar
      testid={testid}
      open={count > 0}
      onClose={() => dismiss()}
      icon={<PulsatingDot />}
      content={content}
      contentClasses={{
        message: classes.message,
        innerContent: classes.innerContent,
        contentButtons: classes.contentButtons,
      }}
      buttons={
        singleLiveEvent ? (
          <>
            <DiscoButton
              testid={`${testid}.event-details`}
              to={{
                ...location,
                search: setSearchParams<GlobalDrawerParams<"event">>(location.search, {
                  drawerOccurrenceId: singleLiveEvent.id,
                  drawerEventTab: "details",
                }),
              }}
              onClick={() => dismiss(singleLiveEvent.id)}
              color={"grey"}
            >
              {`${isMobile ? "" : "Event "}Details`}
            </DiscoButton>
            <OccurrenceRSVPAttendanceStatus
              occurrenceKey={singleLiveEvent}
              onJoinEvent={() => dismiss(singleLiveEvent.id)}
              onRegistration={() => dismiss(singleLiveEvent.id)}
            />
          </>
        ) : multipleLiveEvents ? (
          <DiscoButton
            to={{ pathname: ROUTE_NAMES.COMMUNITY.EVENTS_CALENDAR.ROOT }}
            onClick={() => dismiss()}
          >
            {`See${isMobile ? "" : " Live"} Events`}
          </DiscoButton>
        ) : null
      }
    />
  )

  function shouldHideBar() {
    return (
      routeValues(ROUTE_NAMES.COMMUNITY.JOIN).includes(location.pathname) ||
      routeValues(ROUTE_NAMES.AUTHENTICATION).includes(location.pathname) ||
      routeValues(ROUTE_NAMES.ONBOARDING).includes(location.pathname) ||
      location.pathname.includes("/register") ||
      location.pathname.includes("integration")
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  message: {
    width: "80%",
  },
  innerContent: {
    width: "95%",
    alignItems: "center",
    flexWrap: "nowrap",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  contentButtons: {
    flex: "none",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1, 0),
      flex: "1 0 auto",
    },
  },
}))

export default Relay.withSkeleton({
  component: LiveEventBar,
  skeleton: () => null,
})
