import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { StreamChatContextQuery } from "@/core/context/__generated__/StreamChatContextQuery.graphql"
import { StreamChatContext_ChannelsFragment$key } from "@/core/context/__generated__/StreamChatContext_ChannelsFragment.graphql"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { ArrayUtils } from "@utils/array/arrayUtils"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { Channel, DefaultGenerics, StreamChat } from "stream-chat"
import { Chat, useChatContext } from "stream-chat-react"
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types"

const STREAM_CHAT_CHANNELS_FETCH_LIMIT = 30 // default = 10, max = 30
const STREAM_CHAT_MESSAGES_FETCH_LIMIT = 100 // default = 25, max = 300

let streamChatClient: StreamChat<DefaultGenerics> | undefined
function getStreamChatClient() {
  if (!streamChatClient)
    streamChatClient = new StreamChat(STREAM_API_KEY, { timeout: 10000 })
  return streamChatClient
}

type ChatChannel = {
  id: GlobalID
  externalChannelId: string
  productId: GlobalID | null
  appId: GlobalID | null
}

export type StreamChatContextValue = {
  isConnected: boolean
  setIsConnected: React.Dispatch<React.SetStateAction<boolean>>
  streamChannels: Channel<DefaultStreamChatGenerics>[]
  directMessages: ChatChannel[]
  productChannels: ChatChannel[]
  productMemberChannels: ChatChannel[]
  communityChannels: ChatChannel[]
  chatClient: StreamChat<DefaultGenerics> | null
  setStreamChannels: React.Dispatch<
    React.SetStateAction<Channel<DefaultStreamChatGenerics>[]>
  >
  hasLoadedChannels: boolean
}
const StreamChatContext = React.createContext({
  directMessages: [] as ChatChannel[],
} as StreamChatContextValue)

export function useStreamChat() {
  return useContext(StreamChatContext)
}

/** Sets the StreamChat SDK in context when it is connected. */
export const StreamChatProvider: React.FC = (props) => {
  const activeOrganization = useActiveOrganization()
  const [isConnected, setIsConnected] = useState<boolean>(false)
  const [streamChannels, setStreamChannels] = useState<
    Channel<DefaultStreamChatGenerics>[]
  >([])
  const [hasLoadedChannels, setHasLoadedChannels] = useState<boolean>(false)

  // Need to always create the chat client since it's required for the <Chat> component,
  // and conditionally rendering either <Chat> or just the children causes a bug where
  // the children get mounted twice
  const chatClient = getStreamChatClient()

  const { organization } = Relay.useBackgroundQuery<StreamChatContextQuery>(
    graphql`
      query StreamChatContextQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            id
            streamChatTeamId
            isDmEnabled
            isChannelsEnabled
            viewerMembership {
              streamChatUserId
              streamChatUserToken
            }
            ...StreamChatContext_ChannelsFragment
          }
        }
      }
    `,
    { id: activeOrganization?.id ?? "" }
  )

  // Separate fragment so it can be re-used elsewhere to update store
  const orgWithChannels = useFragment<StreamChatContext_ChannelsFragment$key>(
    graphql`
      fragment StreamChatContext_ChannelsFragment on Organization {
        chatChannels(
          kinds: [default, custom, direct_message]
          includeProductLevel: true
        ) {
          edges {
            node {
              id
              productId
              externalChannelId
              kind
              appId
              product {
                viewerMembership {
                  id
                }
              }
            }
          }
        }
      }
    `,
    organization
  )

  const teamId = organization?.streamChatTeamId
  const userId = organization?.viewerMembership?.streamChatUserId
  const userToken = organization?.viewerMembership?.streamChatUserToken
  const isWebView = useIsWebView()

  const allChatChannels = Relay.connectionToArray(orgWithChannels?.chatChannels)

  // Connect/disconnect as organization and authUser change
  const hasCredentials = Boolean(teamId && userId && userToken)
  const shouldNotConnect =
    isWebView ||
    !hasCredentials ||
    (!organization?.isDmEnabled && !organization?.isChannelsEnabled)

  useEffect(() => {
    if (shouldNotConnect) return

    chatClient
      .connectUser({ id: userId!, subdomain: SUBDOMAIN }, userToken)
      .then(() => setIsConnected(true))

    return () => {
      chatClient.disconnectUser()
      setIsConnected(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, userId, userToken, shouldNotConnect])

  const hasQueriedRef = useRef(false)

  const externalChatChannelIds = allChatChannels
    .filter((occ) => occ.kind !== "direct_message")
    .map((occ) => occ.externalChannelId)
  const externalDMChannelIds = allChatChannels
    .filter((occ) => occ.kind === "direct_message")
    .map((occ) => occ.externalChannelId)

  useEffect(() => {
    // Don't run if the client isn't connected yet
    if (!isConnected) return
    // Only run once when ready to
    if (hasQueriedRef.current) return
    hasQueriedRef.current = true

    let chunkQueries: Promise<Channel<DefaultStreamChatGenerics>[]>[] = []

    if (externalChatChannelIds.length) {
      // Chunk the channel ids to be fetch in separate requests
      const chunks = ArrayUtils.chunks(
        externalChatChannelIds,
        STREAM_CHAT_CHANNELS_FETCH_LIMIT
      )

      const queryStreamChannels = (streamChatChannelIds: string[]) => {
        return chatClient.queryChannels(
          { id: { $in: streamChatChannelIds.filter(Boolean) } },
          {},
          {
            limit: STREAM_CHAT_CHANNELS_FETCH_LIMIT,
            message_limit: STREAM_CHAT_MESSAGES_FETCH_LIMIT,
            watch: true,
          }
        )
      }

      chunkQueries = chunkQueries.concat(chunks.map(queryStreamChannels))
    }

    if (externalDMChannelIds.length) {
      const dmChunks = ArrayUtils.chunks(
        externalDMChannelIds,
        STREAM_CHAT_CHANNELS_FETCH_LIMIT
      )

      // Only tracking presence for DM channels
      const queryDMStreamChannels = (streamChatChannelIds: string[]) => {
        return chatClient.queryChannels(
          { id: { $in: streamChatChannelIds.filter(Boolean) } },
          {},
          {
            limit: STREAM_CHAT_CHANNELS_FETCH_LIMIT,
            message_limit: STREAM_CHAT_MESSAGES_FETCH_LIMIT,
            watch: true,
            presence: true,
          }
        )
      }

      chunkQueries = chunkQueries.concat(dmChunks.map(queryDMStreamChannels))
    }

    if (!chunkQueries.length) {
      setHasLoadedChannels(true)
      return
    }
    // Fetch the channels from stream
    Promise.all(chunkQueries)
      .then((result) => {
        setStreamChannels((prev) => [...prev, ...result.flat()])
      })
      .finally(() => setHasLoadedChannels(true))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected])

  // Sort chat channels by type
  const directMessages = []
  const productChannels = []
  const productMemberChannels = []
  const communityChannels = []
  for (const cc of allChatChannels) {
    if (cc.kind === "direct_message") {
      directMessages.push(cc)
    } else if (cc.productId) {
      productChannels.push(cc)
      // Admins may be added to channels in products they are not members of
      // Get all product channels that the user is a member of the product for
      if (cc.product?.viewerMembership) productMemberChannels.push(cc)
    } else {
      communityChannels.push(cc)
    }
  }

  return (
    <StreamChatContext.Provider
      value={{
        isConnected,
        setIsConnected,
        streamChannels: streamChannels.filter(Boolean),
        setStreamChannels,
        directMessages,
        productChannels,
        productMemberChannels,
        communityChannels,
        chatClient: shouldNotConnect ? null : chatClient,
        hasLoadedChannels,
      }}
    >
      <Chat client={chatClient} theme={""}>
        {props.children}
      </Chat>
    </StreamChatContext.Provider>
  )
}

export function useAddStreamChannelToContext() {
  const { client } = useChatContext()
  const { setStreamChannels } = useStreamChat()

  return useCallback(
    (externalChatChannelId: string, isDM = false) => {
      // No client means this is the first channel initializing chat.
      // We don't need to query as it will be fetched by the context
      // provider when connecting to stream
      if (!client) return

      client
        .queryChannels({ id: externalChatChannelId }, {}, { watch: true, presence: isDM })
        .then((result) => {
          const newStreamChannel = result[0]
          if (!newStreamChannel) return // Channel wasn't found so exit
          setStreamChannels((prev) => [...prev, newStreamChannel])
          return newStreamChannel
        })
    },
    [client, setStreamChannels]
  )
}

function useAddStreamChannelsToContext() {
  const { client } = useChatContext()
  const { setStreamChannels } = useStreamChat()

  return useCallback(
    async (externalChatChannelIds: string[], isDm = false) => {
      if (!client) return []

      const newStreamChannels = await client.queryChannels(
        { id: { $in: externalChatChannelIds } },
        {},
        { watch: true, presence: isDm }
      )
      if (!newStreamChannels.length) return []

      setStreamChannels((prev) => [...prev, ...newStreamChannels])
      return newStreamChannels
    },
    [client, setStreamChannels]
  )
}

/** Get StreamChat Channel object for the given ID. Will return null if not connected. */
export function useStreamChannel(
  channelId: string | null | undefined,
  isDM = false
): Channel<DefaultStreamChatGenerics> | null | undefined {
  const addStreamChannelToContext = useAddStreamChannelToContext()
  const { streamChannels, hasLoadedChannels } = useStreamChat()
  if (!channelId || !hasLoadedChannels) return null

  const streamChannel = streamChannels.find((sc) => sc.id === channelId)

  // If can not find the stream channel, add it to the store
  if (!streamChannel) {
    addStreamChannelToContext(channelId, isDM)
  }

  return streamChannel
}

/** Get StreamChat Channel objects for the given IDs. Will return an empty array if no channels were found. */
export function useStreamChannels(
  channelIds: string[] | null | undefined,
  isDm = false
): Channel<DefaultStreamChatGenerics>[] {
  const addStreamChannelsToContext = useAddStreamChannelsToContext()
  const { streamChannels, hasLoadedChannels } = useStreamChat()
  const [channels, setChannels] = useState<Channel<DefaultStreamChatGenerics>[]>([])

  useEffect(() => {
    if (!channelIds?.length || !hasLoadedChannels) return

    const missingChannels: Channel<DefaultStreamChatGenerics>[] = []
    const channelIdsToFetch = []
    for (const channelId of channelIds) {
      // Channel is already in the "channels" state
      const existingChannel = channels.find((c) => c.id === channelId)

      // Channel is missing from the "channels" state
      if (!existingChannel) {
        const streamChannel = streamChannels.find((sc) => sc.id === channelId)

        if (streamChannel) {
          missingChannels.push(streamChannel)
        } else {
          channelIdsToFetch.push(channelId)
        }
      }
    }

    // Fetch any channels not yet loaded from Stream in a single batch
    if (channelIdsToFetch.length) addStreamChannelsToContext(channelIdsToFetch, isDm)

    // Add the missing streamChannels to the "channels" state if not empty
    if (missingChannels.length) setChannels((prev) => [...prev, ...missingChannels])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamChannels, channelIds])

  return channels
}

/** Sort StreamChat Channel objects by unread count */
export function sortStreamChannelsByUnreads(
  channels: Channel<DefaultStreamChatGenerics>[] | undefined | null
): Channel<DefaultStreamChatGenerics>[] | undefined | null {
  return channels?.sort((a, b) => b.state.unreadCount - a.state.unreadCount)
}
