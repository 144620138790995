import React, { Component, ErrorInfo } from "react"
import { sendSentryAnException } from "../sentryHandler"

const ErrorBoundaryFallback = React.lazy(
  () => import("@/core/error/ErrorBoundaryFallback")
)
const RetryableErrorOverlayFallback = React.lazy(
  () => import("@/core/error/RetryableErrorOverlayFallback")
)
type TErrorBoundaryState = ReturnType<typeof getInitialState>

function getInitialState() {
  return Object.freeze({
    error: null as null | Error,
    eventId: "" as string,
    isNetworkError: false,
  })
}

/** Returns true if the error object is a Network Error that we generally ignore. */
export function isNetworkError(error: unknown): error is Error {
  if (!(error instanceof Error)) return false
  if (error.name === "ChunkLoadError") return true
  if (error.message === "Network Error") return true

  return false
}

class ErrorBoundary extends Component<Record<string, unknown>, TErrorBoundaryState> {
  /* eslint-disable react/sort-comp */
  readonly state = getInitialState()

  static getDerivedStateFromError(error: Error) {
    return { error, isNetworkError: isNetworkError(error) }
  }
  /* eslint-enable react/sort-comp */

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (!this.state.isNetworkError) {
      sendSentryAnException(error, {
        extra: {
          title: "ErrorBoundary",
          data: errorInfo,
        },
        onCapture: this.setSentryEventId,
      })
    }
  }

  setSentryEventId = (eventId: string) => {
    this.setState({ eventId })
  }

  render() {
    const { error, eventId } = this.state

    /** Present an error overlay to let user refresh the page */
    if (this.state.isNetworkError) return <RetryableErrorOverlayFallback />

    if (error) {
      return <ErrorBoundaryFallback error={error} eventId={eventId} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
