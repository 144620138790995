import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoButton, DiscoText } from "@disco-ui"

const RetryableErrorOverlayFallback = () => {
  const classes = useStyles()

  return (
    <div className={classes.errorOverlay}>
      <div className={classes.container}>
        <div className={classes.mainContent}>
          <DiscoText variant={"body-lg-600"} marginBottom={1}>
            {"Looks like we lost it on the dance floor..."}
          </DiscoText>
          <DiscoText marginBottom={3}>
            {"An unexpected error occurred, please refresh and try again"}
          </DiscoText>
          <DiscoButton onClick={() => window.location.reload()}>{"Refresh"}</DiscoButton>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeUseStyles({
  errorOverlay: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    backgroundColor: "#fff",
    zIndex: 99,
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  mainContent: {
    textAlign: "center",
  },
})

export default RetryableErrorOverlayFallback
