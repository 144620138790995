import useSearchClient from "@/apps/util/hooks/useSearchClient"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import SearchModal from "@/search/SearchModal"
import { SearchType } from "@/search/utils/searchUtils"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import useOnKeydown from "@utils/hook/useOnKeydown"
import { createContext, ReactNode, useCallback, useContext, useState } from "react"
import {
  InstantSearch,
  useCurrentRefinements,
  useRefinementList,
  useSearchBox,
} from "react-instantsearch-hooks-web"

export const SearchContext = createContext<{
  query: string
  refine: (value: string) => void
  isSearchOpen: boolean
  openSearch?: () => void
  closeSearch?: () => void
  filter: (value: string) => void
  appliedFilters: SearchType[]
}>({
  query: "",
  refine: (value: string) => value,
  isSearchOpen: false,
  appliedFilters: [],
  filter: (value: string) => value,
})

export function useSearchContext() {
  return useContext(SearchContext)
}

interface SearchProviderProps {
  children: ReactNode
}

function SearchProvider({ children }: SearchProviderProps) {
  const search = useSearchClient()
  if (!search) return <>{children}</>

  const { searchClient, indexName } = search

  return (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      <SearchProviderWithClient>{children}</SearchProviderWithClient>
    </InstantSearch>
  )
}

function SearchProviderWithClient({ children }: SearchProviderProps) {
  const { globalSearch } = useFeatureFlags()
  const activeOrganization = useActiveOrganization()
  const { query, refine } = useSearchBox()
  const isMobile = useIsMobile()
  const { refine: filter } = useRefinementList({ attribute: "type" })
  const { items: currentRefinements } = useCurrentRefinements()
  const appliedFilters = currentRefinements
    ?.map((cr) => cr.refinements.map((r: any) => (r as any).value as SearchType))
    .flat()

  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const canSearch = Boolean(activeOrganization?.viewerMembership && globalSearch)

  const openSearch = useCallback(() => {
    setIsSearchOpen(true)
  }, [])

  const closeSearch = useCallback(() => {
    setIsSearchOpen(false)
  }, [])

  useOnKeydown(
    (e) => {
      if (!isSearchOpen && (e.metaKey || e.ctrlKey) && e.code === "KeyK") {
        e.preventDefault()
        openSearch()
      }
    },
    [isSearchOpen]
  )

  return (
    <SearchContext.Provider
      value={{
        query,
        refine,
        isSearchOpen,
        openSearch,
        closeSearch,
        filter,
        appliedFilters,
      }}
    >
      {children}

      {canSearch && isMobile && (
        <SearchModal
          isOpen={isSearchOpen}
          onClose={closeSearch}
          title={""}
          testid={"SearchButton"}
          modalContentLabel={"SearchModal"}
        />
      )}
    </SearchContext.Provider>
  )
}

export default SearchProvider
