import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import UnstyledRoute from "@/core/route/unstyled-route/UnstyledRoute"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import MetaTitle from "@/main/page/MetaTitle"
import { usePermissionsFragment$key } from "@utils/hook/__generated__/usePermissionsFragment.graphql"
import usePermissions from "@utils/hook/usePermissions"
import { generatePath, Redirect, Route, RouteProps } from "react-router-dom"

export type PermissionedRouteProps = RouteProps & {
  metaTitle?: string
  entityKey: usePermissionsFragment$key | null
  permission: string | string[]
  /** The fallback route if the permission check fails */
  fallback?: string
  unstyled?: boolean
}

function PermissionedRoute(props: PermissionedRouteProps) {
  const { metaTitle, entityKey, permission, fallback, unstyled, ...routeProps } = props
  const activeProduct = useActiveProduct()
  const { authUser } = useAuthUser()

  const viewerPermissions = usePermissions(entityKey)
  const hasPermissions = Array.isArray(permission)
    ? permission.some((p) => viewerPermissions.has(p))
    : viewerPermissions.has(permission)

  return (
    <>
      <MetaTitle>{metaTitle}</MetaTitle>
      {renderRoute()}
    </>
  )

  function renderRoute() {
    // Permission check passes.
    if (hasPermissions) {
      if (unstyled) {
        return <UnstyledRoute {...routeProps} />
      }
      return <Route {...routeProps} />
    }

    // If the permission check fails, and there is a fallback, redirect to the fallback.
    if (fallback) {
      return <Redirect to={fallback} />
    }

    // Permission check failed - redirect logged-in users to most specific possible page.
    if (authUser) {
      if (activeProduct)
        return (
          <Redirect
            to={generatePath(ROUTE_NAMES.PRODUCT.LANDING_PAGE.SITE, {
              productSlug: activeProduct.slug,
            })}
          />
        )
      return <Redirect to={ROUTE_NAMES.COMMUNITY.LANDING_PAGE.SITE} />
    }

    // allow anonymous users to login and be redirected to page they are trying to visit
    return (
      <Redirect
        to={{
          pathname: ROUTE_NAMES.AUTHENTICATION.LOGIN,
          state: { redirectUrl: window.location.href },
        }}
      />
    )
  }
}

export default PermissionedRoute
