import { useAuthUser } from "@/core/context/AuthUserContext"
import UnstyledRoute from "@/core/route/unstyled-route/UnstyledRoute"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import MetaTitle from "@/main/page/MetaTitle"
import { Redirect, Route, RouteProps } from "react-router-dom"

type AuthRequiredRouteProps = RouteProps & {
  metaTitle?: string
  unstyled?: boolean
}

export default function AuthRequiredRoute(props: AuthRequiredRouteProps) {
  const { metaTitle, unstyled, ...routeProps } = props

  const { authUser } = useAuthUser()

  return (
    <>
      <MetaTitle>{metaTitle}</MetaTitle>
      {renderRoute()}
    </>
  )

  function renderRoute() {
    // Permission check passes.
    if (authUser) {
      if (unstyled) {
        return <UnstyledRoute {...routeProps} />
      }
      return <Route {...routeProps} />
    }
    // FUTURE: support a prop for custom redirect paths?
    return (
      <Redirect
        to={{
          pathname: ROUTE_NAMES.AUTHENTICATION.LOGIN,
          state: { redirectUrl: window.location.href },
        }}
      />
    )
  }
}
