/**
 * @generated SignedSource<<921d8300a64917a025a872cef8b05dc6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ActiveSlackUserImportContext_PendingMatchesQuery$variables = {
  id: string;
  needsMapping: boolean;
};
export type ActiveSlackUserImportContext_PendingMatchesQuery$data = {
  readonly slackUserImport: {
    readonly id?: string;
    readonly pendingMatches?: {
      readonly __id: string;
      readonly totalCount: number;
    };
  } | null;
};
export type ActiveSlackUserImportContext_PendingMatchesQuery = {
  variables: ActiveSlackUserImportContext_PendingMatchesQuery$variables;
  response: ActiveSlackUserImportContext_PendingMatchesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "needsMapping"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "condition": "needsMapping",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": "pendingMatches",
      "args": [
        {
          "kind": "Literal",
          "name": "status",
          "value": "pending"
        },
        {
          "kind": "Literal",
          "name": "unmapped",
          "value": true
        }
      ],
      "concreteType": "SlackUserImportMemberNodeConnection",
      "kind": "LinkedField",
      "name": "members",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": "members(status:\"pending\",unmapped:true)"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActiveSlackUserImportContext_PendingMatchesQuery",
    "selections": [
      {
        "alias": "slackUserImport",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "SlackUserImport",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActiveSlackUserImportContext_PendingMatchesQuery",
    "selections": [
      {
        "alias": "slackUserImport",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "SlackUserImport",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5c2a515691937e45961121d0a76b950b",
    "id": null,
    "metadata": {},
    "name": "ActiveSlackUserImportContext_PendingMatchesQuery",
    "operationKind": "query",
    "text": "query ActiveSlackUserImportContext_PendingMatchesQuery(\n  $id: ID!\n  $needsMapping: Boolean!\n) {\n  slackUserImport: node(id: $id) {\n    __typename\n    ... on SlackUserImport {\n      id\n      pendingMatches: members(unmapped: true, status: pending) @include(if: $needsMapping) {\n        totalCount\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fccf1cd1f324937d8d4a8547eff4be65";

export default node;
