import React, { createContext, useContext, useState } from "react"

export interface StepperContextValue {
  steps: [activeStep: number, totalSteps: number]
  direction: "next" | "previous"
  next: VoidFunction
  previous: VoidFunction
  hasNext: boolean
  hasPrevious: boolean
  onAfterLast?: VoidFunction
  onBeforeFirst?: VoidFunction
}

export const StepperContext = createContext<StepperContextValue | null>(null)

export function useStepperContext() {
  return useContext(StepperContext)
}

type StepperContextProviderProps = {
  steps: StepperContextValue["steps"]
  direction?: StepperContextValue["direction"]
  /** optional function called when navigation to beyond last */
  onAfterLast?: StepperContextValue["onAfterLast"]
  /** optional function called when navigating to before first */
  onBeforeFirst?: StepperContextValue["onBeforeFirst"]
  children: React.ReactNode
}

export default function StepperContextProvider({
  steps,
  direction: initialDirection = "next",
  onAfterLast,
  onBeforeFirst,
  children,
}: StepperContextProviderProps) {
  const [activeStep, setActiveStep] = useState(steps[0])
  const [direction, setDirection] =
    useState<StepperContextValue["direction"]>(initialDirection)
  const totalSteps = steps[1]
  const hasPrevious = activeStep > 0
  const hasNext = activeStep < totalSteps - 1

  const value: StepperContextValue = {
    steps: [activeStep, totalSteps],
    direction,
    next,
    previous,
    hasPrevious,
    hasNext,
    onAfterLast,
    onBeforeFirst,
  }

  return <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
  function next() {
    setDirection("next")
    if (!hasNext) return onAfterLast?.()
    setActiveStep((prev) => {
      return prev + 1
    })
  }

  function previous() {
    setDirection("previous")
    if (!hasPrevious) return onBeforeFirst?.()
    setActiveStep((prev) => prev - 1)
  }
}
