import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import UnstyledRoute from "@/core/route/unstyled-route/UnstyledRoute"
import CommunityNonActiveStandingPage from "@/main/page/CommunityNonActiveStandingPage"
import MetaTitle from "@/main/page/MetaTitle"
import useSubscriptionStanding from "@utils/hook/useSubscriptionStanding"
import { Route, RouteProps } from "react-router-dom"

type GoodStandingRequiredRouteProps = RouteProps & {
  metaTitle?: string
  unstyled?: boolean
}

export default function GoodStandingRequiredRoute(props: GoodStandingRequiredRouteProps) {
  const { metaTitle, unstyled, ...routeProps } = props

  const activeOrganization = useActiveOrganization()
  const subscriptionStanding = useSubscriptionStanding()
  return (
    <>
      <MetaTitle>{metaTitle}</MetaTitle>
      {renderRoute()}
    </>
  )

  function renderRoute() {
    if (activeOrganization && subscriptionStanding === "active") {
      if (unstyled) {
        return <UnstyledRoute {...routeProps} />
      }
      return <Route {...routeProps} />
    }

    return <CommunityNonActiveStandingPage />
  }
}
